import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { OrganizationProject } from "../../../../Wallets/OrganizationWallets";
import { useTranslation } from "react-i18next";
import { currencyList } from "../../../../../utils/currencyMap";
interface ProgramCurrencyProps {
  project: OrganizationProject;
  readOnly: boolean;
  disabled: boolean;
}

export const ProgramCurrencyInput = ({
  project,
  readOnly,
  disabled,
}: ProgramCurrencyProps) => {

   const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("program_currency_validation");
  }
  return false;
}; 
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("selected_currency", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.project_currency || "",
  });
  const { t } = useTranslation();


 

  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>{t('project_currency')}</label>
        <div style={{ height: 50, width: 200 }}>
          <TextInput
            {...getInputProps()}
            type="text"
            placeholder={project?.project_details?.project_currency}
            name="projectName"
            readOnly={readOnly}
          />
          {isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {/* {meta.error} */}
            </p>
          )}
        </div>
      </li>
    );
  }

  

  return (
    <li className="settings__card-input-list-item">
      <label>{t('project_currency')}</label>
      <div >
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: t("select_currency"), value: "" },
            ...currencyList
          ]}
          required
          name="projectFocus"
          disabled={disabled}
          style={{ height: 30, width: 200 }}
          fill
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0, marginTop: 5 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};


