import { useQuery } from "react-query";

import { searchParticipant } from "../services";

export default function useSearchParticipant(
  organizationId: string | number,
  projectId: string | number,
  search: string,
  finalSelectedWallet: any
) {
  const groupQuery = useQuery(
    ["wallets", organizationId, projectId, search],
    () => searchParticipant(organizationId, projectId, search),
    { initialData: [], enabled: !!organizationId && !!projectId, select: (data) => data?.map((wallet: any) => {
       return {
         user_type: wallet.type_id === 1 ? "Recipient" : "Merchant",
         selected: finalSelectedWallet?.find((item: any) => item.id === wallet.id) ? true : false,
         ...wallet
       }
      })}
  );

  return groupQuery?.data
}
