import { Tab, Tabs } from "@blueprintjs/core";
import { Trans } from "react-i18next";

interface ChartSelectProps {
  toggleChart: Function;
  projectId: string;
}

export const ChartSelect = ({ toggleChart, projectId }: ChartSelectProps) => {
  return (
    <div className="featured__charts-chart-select">
      <h2 className="featured__charts-title">
        <Trans i18nKey="program_no" />. {projectId}
      </h2>
      <Tabs
        id="featured-transactions-selector"
        className="featured__charts-chart-labels"
        onChange={(id) => toggleChart(id)}
      >
        <Tab id="primary" className="featured__charts-chart-label">
          {<Trans i18nKey="average_transactions" />}
        </Tab>
        <Tab id="secondary" className="featured__charts-chart-label">
          {<Trans i18nKey="offline_transactions" />}
        </Tab>
      </Tabs>
    </div>
  );
};
