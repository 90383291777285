import { useQuery } from "react-query";

import { getWhitelisted } from "../services";

export default function useWhitelistedUsers(organizationId: string | number) {
  return useQuery(
    ["whitelisted", organizationId],
    () => getWhitelisted(organizationId),
    { initialData: [], enabled: !!organizationId }
  );
}