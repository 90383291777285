import { Classes } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  UmojaBarChartWidget,
  UmojaLineChartWidget,
  UmojaPieChartWidget,
} from "../../components";
import {
  useProjects,
  useProjectTransactionsHistory,
  useWallets,
} from "../../hooks";
import { useOrganizationId } from "../../hooks/useOrganizationId";
import useProjectActivityStats from "../../hooks/useProgamActivityStats";
import { theme } from "../../themes/chartTheme";
import {
  groupTransactionsByOfflineStatus,
  segmentOrganizationAgeGroup,
  totalIncrementingTransactionVolume,
} from "./util";


// TODO: configure component to read from a customizable configuration
// of multiple charts. ie: As a user, I want to be able to configure
// which charts I see, by adding them and removing them dynamically.
export const ProjectActivityCharts = () => {
  const { t } = useTranslation();
  const selectedOrganizationId = useOrganizationId();
  const { organizationId } = useParams<{
    organizationId: string;
  }>();
  const projects = useProjects();
  const projectId = projects?.data?.programs?.find(
    (data: any) => data.organization_id === +selectedOrganizationId
  );
  const activityStats = useProjectActivityStats(organizationId, projectId?.id);
  
  // TODO: must scale to support multiple programs

  const transactionHistory = useProjectTransactionsHistory( organizationId, projectId?.id );

  const organizationWallets = useWallets(organizationId);

  if (!transactionHistory.isFetched && !organizationWallets.isFetched) {
    return (
      <div
        className={`activity__charts-outer-wrapper--loading ${Classes.SKELETON}`}
      ></div>
    );
  }

  const transactionHistoryGraphData = groupTransactionsByOfflineStatus({
    data: activityStats.data,
  });
  
  const totalTransactionVolume = totalIncrementingTransactionVolume({
    data: activityStats.data,
  });

  const ageGroupDemographicData = segmentOrganizationAgeGroup({
    data: activityStats.data.age_group_count
  });
  
  return (
    <div className="activity__charts-outer-wrapper">
      {transactionHistoryGraphData && (
        <div className="activity__charts-widget">
          <UmojaBarChartWidget
            theme={theme.lightDefault}
            title={`${t("offline_online_transaction")}`}
            data={transactionHistoryGraphData}
            padding={{ top: 0, left: 70, bottom: 0, right: 50 }}
            domainPadding={{ x: 150, y: [0, 0] }}
            tickValues={[`${t("online")}`, `${t("offline")}`]}
          ></UmojaBarChartWidget>
        </div>
      )}
      {totalTransactionVolume && (
        <div className="activity__charts-widget">
          <UmojaLineChartWidget
            title={`${t("transaction_volume")}`}
            data={totalTransactionVolume}
            currency="$"
            theme={theme.lightDefault}
            padding={{ top: 20, left: 60, bottom: 40, right: 60 }}
            domainPadding={{ x: 25, y: 30 }}
            tickValues={[1, totalTransactionVolume.length]}
            tickFormat={[
              totalTransactionVolume[0].x,
              totalTransactionVolume[totalTransactionVolume.length - 1].x,
            ]}
            axisPadding={{ top: 10, left: 0, bottom: 20, right: 0 }}
          ></UmojaLineChartWidget>
        </div>
      )}
      {ageGroupDemographicData && (
        <div className="activity__charts-widget">
          <UmojaPieChartWidget
            theme={theme.lightDefault}
            title={`${t("age_group_breakdown")}`}
            data={ageGroupDemographicData}
            padding={{ top: 50, left: 20, bottom: 40, right: 20 }}
            domainPadding={{ x: 20, y: 30 }}
          />
        </div>
      )}
    </div>
  );
};
