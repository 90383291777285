export const creole = {
  // languages
  english: "Anglè",
  creole: "Kreyòl",
  french: "Fransè",
  spanish: "Panyòl",
  settings: "Paramèt",
  good_day: "Bon jounen",
  good_evening: "Bonswa",
  step_one: "Etap 1",
  step_two: "Etap 2",
  step_three: "Etap 3",
  step_four: "Etap 4",
  next_step: "Swivan",
  section_complete: "Seksyon konplè",
  section_incomplete: "Seksyon enkonplè",
  update: "", // missing
  save: "Anrejistre",
  dismiss: "Rejte",
  phone_number: "Nimewo telefòn",
  // Account Settings
  account_information: "Enfòmasyon sou kont",
  account_information_subtitle:
    "Gade enfòmasyon sou kont ou ak estati aktyèl li",
  name: "Non",
  email_address: "Adrès imèl",
  edit: "Modifye",
  change_your_password: "Chanje modpas ou",
  current_password: "Modpas aktyèl",
  new_password: "Nouvo modpas",
  confirm_password: "Konfime modpas",
  confirm_new_password: "Konfime modpas dwe menm jan ak Nouvo Modpas",
  change_password_any_time: "Chanje modpas ou nenpòt ki lè",
  // Project Management
  create_a_project: "Kreye yon pwojè",
  create_a_project_subtitle: "Kreye premye espas pwojè ou",
  project_title: "Tit pwojè",
  project_type: "Kalite pwojè",
  project_focus: "Objektif pwojè",
  project_language: "Peyi operasyon",
  add_more_participants: "Ajoute plis patisipan",
  enter_project_title: "", // missing
  select_type: "", // missing
  select_focus: "", // missing
  continue_when_ready: "Kontinye lè w pare",
  complete_previous_steps: "Ranpli etap anvan yo",
  project_details: "Pwojè Detay",
  project_details_subtitle: "Defini lokalite ak monnen pwogram lan",
  project_localization: "Lokalizasyon Pwogram",
  operating_country: "Peyi operasyon",
  project_currency: "Monnen pwojè",
  project_goals: "Objektif pwojè",
  target_disbursement_amount: "Sible montan debousman",
  target_merchant_enrollment: "Sible enskripsyon machann",
  target_participant_enrollment: "Sible enskripsyon patisipan",
  target_percent_expenditure: "Sible % lajan pou depanse",
  enroll_participants: "Enskri Patisipan",
  enroll_participants_subtitle:
    "Ajoute benefisyè nan pwogram ak machann nan nouvo pwogram ou an",
  upload_from_kobo: "Enpòte done apati Kobotoolbox",
  upload_data: "telechaje done",
  upload_custom_excel: "Telechaje Excel koutim",
  import_data_from_koboToolbox: "Enpòte done ki soti nan KoboToolbox",
  select_data_source: "Chwazi yon Sous Sone",
  browse: "browse",
  participant_group: "Gwoup benefisyè",
  merchant_group: "Gwoup machann",
  ready_to_deploy: "Pare pou deplwaye",
  ready_to_deploy_subtitle: "Revize detay ak prepare pou egzekite pwojè ou",
  ready_to_launch: "Pare pou lanse",
  everything_checks_out: "Tout bagay verifye",
  launch_project: "Lanse pwojè",
  category_header: "Kreye jiska 16 kategori",
  category_detail:
    "Machann yo itilize kategori yo pou kreye atik nan boutik yo a. Umoja itilize done sa yo pou revele enfòmasyon sou pwojè a.",
  category_input_placeholder: "Antre nan yon kategori atik",
  new_project_description:
    "Itilizatè tablodbò Umoja ki gen pèmisyon administratè kapab kreye nouvo pwojè ak debouse lajan sou rezo Umoja a",
  help_center: "Sant Èd la",
  cash_out_day: "Jou Retire Lajan",
  select_cash_out: "Chwazi jou retire lajan",
  monday: "Lendi",
  tuesday: "Madi",
  wednesday: "Mèkredi",
  thursday: "Jedi",
  friday: "Vandredi",
  health: "Sante",
  nutrition: "Nitrisyon",
  food_security: "Sekirite Alimantè",
  cash_assistance: "Asistans Lajan Kach",
  voucher_assistance: "Asistans pou Koupon",
  housing_shelter: "Lojman/Abri",
  water_and_sanitation: "Dlo ak Ijyèn",
  livelihood_and_economic_support: "Mwayen Sibzistans/Sipò Ekonomik",
  education: "Edikasyon",
  gender_equality: "Ekite Seksyèl",
  humanitarian_relief: "Pwogram Imanitè/Sekou",
  development: "Pwogram Devlopman",
  microFinance: "Pwogram Mikwofinans",
  private_sector: "Pwogram Sektè Prive (Biznis)",
  create_first_project: "Kreye premye espas pwojè ou",
  setup_locality: "Defini lokalite ak monnen pwogram lan",
  define_product: "Defini kategori pwodwi",
  enroll_participant: "Enskri patisipan ak machann yo",
  create_project: "Kreye yon pwojè",
  cash_out_validation_error: "Jou Retire Lajan obligatwa",

  wallet_groups: "Wallet Group",
  select_wallet: "Select a Wallet Group",
  create_new_wallet: "Create New Wallet Group",
  manage_wallet_group: "Manage Wallet Groups",
  group_name: "Group Name",
  wallet_group: "Wallet Groups",
  search_participants: "Search for Participants to add to groups",
  delete_group: "Delete Group",
  add_to_list: "Add to List",
  create_group: "Create Group",
  selected: "Selected",
  remove_from_list: "Remove from List",

  // Organization Settings
  organization_information: "Enfòmasyon sou òganizasyon",
  organization_information_subtitle:
    "Gade enfòmasyon sou òganizasyon ak ekip ou",
  organization_name: "Non òganizasyon",
  permission_level: "Nivo pèmisyon",
  manage_team_members: "Jere manm ekip",
  manage_team_members_subtitle:
    "Gade manm ekip aktyèl la, ajoute nouvo manm ekip epi mete ajou estati manm lan",
  invite_team_member: "Envite yon manm ekip",
  access_level: "Nivo aksè",
  date_added: "Dat ki ajoute",
  email_sent_successfully: "Imel voye avèk siksè",
  error_completing_request: "Erè lè w fin ranpli demann ou an",
  email_to_send_invite: "Ki adrès imel ou ta renmen voye yon envitasyon ekip?",
  valid_email_required: "Yon adrès imel ki valab obligatwa",
  confirm: "Konfime",
  back: "Retounen",

  // Settings Sidebar
  your_account_settings: "Paramèt Kont Ou",
  organization_settings: "Paramèt Òganizasyon",
  project_management: "Jesyon Pwojè",
  additional_resources: "Resous Siplemantè",
  support: "Sipò",
  // Team
  team: "Ekip",
  role: "Wòl",
  // Wallet Explorer
  wallet_explorer: "Eksploratè Pòtfèy",
  merchant: "Machann",
  Merchant: "Machann",
  recipient: "Moun k ap resevwa",
  Recipient: "Moun k ap resevwa",
  from: "Soti nan",
  To: "Pou",
  status: "Estati",
  txn_type: "Kalite Txn",
  program_code: "Pwogram Kòd",
  enrollment_date: "Dat enskripsyon",
  account_status: "Estati Kont",
  last_active: "Dènye aktif",
  user_type: "Kalite Itilizatè",
  total_volume: "Volim total pwojè",
  total_transactions: "Tranzaksyon total",
  card_reference: "Kat",
  pin_code: "Kòd PIN",
  transaction_explorer: "Eksploratè tranzaksyon",
  edit_data: "Ddite done yo",
  register_card: "Anrejistre Kat",
  unregister_card: "Anile enskripsyon kat la",
  export_data: "Done Ekspòtasyon",
  resume_wallet: "Rezime bous",
  pause_wallet: "Poz bous",
  activity_explorer: "Eksploratè Aktivite",
  Total: "Total",
  transaction_status: "Estati Tranzaksyon",
  transaction_type: "Kalite tranzaksyon",
  timestamp: "timestamp",
  Disburse_to: "Debouse pou",
  Disburse: "Debouse",
  // average_transactions: "",
  // offline_transactions: "",
  total_enrolled: "Total enskri",
  merchants: "Machann",
  register_card_confirm:
    "Klike sou anrejistre kat pou konekte yon kat Umoja ak pòtfèy <strong>{{ wallet }}</strong>",
  unregister_card_confirm:
    "Èske ou sèten ou vle anile enskripsyon kat Umoja sa nan pòtfèy <strong>{{ wallet }}</strong>",

  // Dashboard
  enrollments: "Enskripsyon",
  track_activity: "Swiv aktivite",
  view_wallets: "Gade pòtfèy yo",
  activity: "Aktivite",
  wallets: "Pòtfèy",
  transaction_volume: "Volim tranzaksyon",
  global_operations: "Operasyon Global",
  project: "Pwojè",
  offline_online_transaction: "Tranzaksyon san koneksyon / avèk koneksyon",
  online: "avèk koneksyon",
  offline: "san koneksyon",
  age_group_breakdown: "Repatisyon gwoup laj",
  admin: "Admin",
  viewer: "Vizyonè",
  organizer: "Òganizatè",
  logout: "Dekonekte",
  transactions: "Tranzaksyon",
  participant_pulse: "Puls Patisipan",
  flow_of_funds: "Koule nan fon",
  merchant_wallet_balance: "Balans Mwayèn Machann Bous",
  total_disbursed: "Total Debouse",
  recipient_wallet_balance: "Balans Mwayèn Bous Destinatè",
  total_spent: "Total Depanse",
  total_refunded: "Ranbousman total",
  demographic_insight: "Insight demografik",
  spending_by_gender: "Kategori pwodwi (depans pa sèks)",
  spending_pattern: "Modèl Depans",
  volume_transacted: "Volim Transakted",
  total_txns: "Txns Total",
  average_txn: "Mwayèn Txn",
  last_7_days: "Dènye 7 jou",
  programme_home: "Akèy Jesyon",
  getting_started: "Kòmanse",
  welcome_to_umoja: "Byenvini nan Suit Jesyon Umoja",
  register_program_card: "Enskri kat pwogram",
  project_info:
    "Poko gen okenn pwojè aktif nan espas travay ou. Annatandan, nou ankouraje w aprann plis nan vizite Sant Èd la",
  // Payment Widget
  disburse: "Debouse",
  disbursement_amount: "Montan dekèsman",
  disburse_to: "Debouse pou",
  funded_by: "Finanse pa",
  all_wallets: "Tout pòtfèy",
  primary_wallet: "Pòtfèy prensipal",
  total_disbursement: "Debousman total",
  preview_disbursement: "Apèsi dekèsman",
  all_recipients: "Tout Benefisyè",
  recipients: "Benefisyè",
  all_merchants: "Tout Machann",
  wallet_balance: "Balans pòtfèy",
  disburse_funds: "Debouse lajan",
  latest_disbursements: "Dènye dekèsman yo",
  amount: "Montan",
  send_cash_to_wallet: "Voye lajan kach nan bous",
  master_wallet: "Mèt bous",
  master_wallet_balance: "Mèt Wallet Balans",
  select_target_group: "Chwazi gwoup sib",
  sent: "Voye",
  received: "Resevwa",
  wallet_received: "Bous te resevwa",
  sent_to: "Voye nan",
  send_to: "Voye bay",
  sending: "Voye",
  total_send: "Total Voye",
  new_master_wallet_balance: "Nouvo balans mèt bous",
  send_funds: "Voye Lajan",
  each: "chak",
  no_recent_activity: "Pa aktivite recent",
  // sidebar
  home: "Akèy",
  alerts: "Alèt",
  // breadcrumb
  account_settings: "Paramèt Kont",
  // Additional resource page
  platform_news: "Enfòmasyon sou platfòm",
  legal: "Legal",
  company_support: "Konpayi ak Sipò",
  management_status: "Sitiyasyon jesyon",
  umoja_commerce: "Komès Umoja",
  umoja_wallet: "Pòtfèy Umoja",
  umoja_card: "Kat Umoja",
  terms_of_service: "Tèm sèvis",
  privacy_policy: "Règleman sou Vi Prive",
  GDPR_privacy_policy: "GDPR ak Règleman sou Vi Prive",
  emerging_impact_services: "Sèvis Enpak k ap Emèje",
  umoja_brand_kit: "Twous mak Umoja",
  ecosystem_case_studies: "Etid de ka sou ekosistèm",
  help_center_support: "Sant èd ak sipò",
  careers: "Karyè",
  // Non logged-in pages
  login_page_description:
    "Umoja se yon platfòm inifye pou peman dijital ak peman atravè mache emèjan yo.",
  sign_into_your_account: "Enskri nan kont ou",
  learn_more_about_umoja: "Aprann plis sou Umoja",
  contact_support: "Kontakte Sipò?",
  having_trouble_signin: "Èske ou gen pwoblèm pou ou konekte?",
  sign_in: "Konekte",
  your_email: "Imèl",
  password: "Modpas",
  forgot_password: "Bliye modpas",
  submit: "Soumèt",
  email: "Imèl",
  email_required: "Yon imèl obligatwa",
  valid_email: "Tanpri antre yon adrès imel ki valab",
  password_required: "Modpas obligatwa",
  password_length_validation: "Modpas la dwe gen omwen 8 karaktè",
  reset_password: "Reyajiste modpas",
  // Umoja toast and alerts
  // problem_making_request: "", // TODO
  // disbursement_permission_error: "",
  invalid_credentials_error: "Imèl/modpas la pa valid",
  // signin_error: "",
  // password_changed_successfully: "",
  error_submitting_request: "Kulikuwa na tatizo katika kuwasilisha ombi lako",
  // problem_creating_updating_project: "",
  // role_changed_successfully: "",
  // request_not_completed: "",
  // problem_adding_details: "",
  // successfully_enrolled_users: "",
  // error_uploading_csv_file: "",
  review_imported_data: "Revize Done Enpòte",
  imported_data: "Done Enpòte",
  verify_imported_data_text:
    "Tanpri verifye si tablo done sa a montre kolòn ki kòrèk yo. Umoja pral sèvi ak fòma sa a pou jenere analiz ak done pwogram ou an.",
  remove: "Retire",
  yes_correct: "Wi, sa a kòrèk!",
  // merchants_uploaded_successfully: "",
  // participants_uploaded_successfully: "",
  // problem_publishing_project: "",
  wallet_updated_successfully: "Pòtfèy la te mete ajou avèk siksè",
  // please_try_again: "",
  // register_cards_permission_error: "",
  // problem_registering_umoja_card: "",
  // successfully_registered_card: "",
  // error_updating_email_address: "",
  // successfully_updated_password: "",
  // successfully_updated_email: "",
  // error_updating_password: "",

  // demographics insight data
  female_total_transactions: "FANM",
  male_total_transactions: "GASON",
  lgbtq_total_transactions: "LGBTQ",
  other_total_transactions: "LÒT",

  // redeem invite
  first_name: "Premye non",
  last_name: "Denye non",
  redeem_invite: "Rachte envite",
  successful: "Rezilta",
  enter_first_name: "Antre premye non",
  enter_last_name: "Antre denye non",
  first_name_required: "Premye non obligatwa",
  last_name_required: "Denye non obligatwa",
  only_letters_allowed: "Se sèlman lèt yo gen dwa san espas",
  password_must_match: "Modpas la dwe mete ak konfimasyon",
  character_is_too_short: "Karaktè twò kout",
  INVALID_GENDER: "Done ki pa valab antre",
};
