import "./UmojaChartWidget.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import { useTranslation } from "react-i18next";
import { fmtDecimal } from "../../../utils";

export interface UmojaSpendingPatternChartProps { data: Record<string, string | number>[], currency: string}

export const UmojaSpendingPatternChartV2 = ({ data, currency }: UmojaSpendingPatternChartProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="spending_pattern_chart">
        <ResponsiveContainer width="100%" aspect={2}>
           <LineChart
              width={750}
              height={500}
              data={data ? [...data].reverse() : []}
              margin={{
                  top: 25,
                  right: 10,
                  left: 20,
                  bottom: 20
              }}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis
          dataKey="date"
          tickLine={{ strokeWidth: 2 }}
          tickMargin={5}
        >
        <Label value={`${t("time")}`} position="bottom" />
        </XAxis>
        <YAxis>
          <Label value={`${t("volume")}(${currency})`} position="left" angle={-90}  />   
        </YAxis>
        <Tooltip formatter={(value: string) => fmtDecimal(value)} />
            <Line
              type="monotoneX"
              dataKey="Volume"
              stroke="#8884d8"
              dot={false}
            />
        </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default UmojaSpendingPatternChartV2;


