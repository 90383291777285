import { PropagateLoader } from "react-spinners";
import "./Analytics.css";

type AnalyticsCardProps = {
  title: string;
  leftHeader: string;
  leftContent: number | string;
  rightHeader: string;
  rightContent: number | string;
  isLoading?: Boolean;
}

const AnalyticsCard = ({ title, leftHeader, leftContent, rightHeader, rightContent, isLoading }: AnalyticsCardProps) => {
  return (
    <div className="analytics-container">
      <h4 className="analytics-card_header">{`${title}`}</h4>
      <div className="flex-container">
        <div className="analytics-card_content-wrapper left-border-radius grey-background">
          <p className="analytics-card_left-header">{leftHeader}</p>
          <p className="analytics-card_content-count">{leftContent}</p>
        </div>
        <div className="analytics-card_content-wrapper right-border-radius black-background">
          <p className="analytics-card_right-header">{rightHeader}</p>
          {isLoading ?
            <div className="transaction-card_content-loader">
              <PropagateLoader size={10} color={'#ffffff'} />
            </div> : <p className="analytics-card_content-count">{rightContent}</p>
            }
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard
