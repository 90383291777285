import { Button } from "@blueprintjs/core";
import { ReactSVG } from "react-svg";
import { useFormContext } from "react-form";
import { useTranslation } from "react-i18next";
import { UmojaPaymentWidgetHeader } from "../../__internal/PaymentWidgetHeader";
import { calculateTotalTransactionVolume, getMasterWalletBalance } from "../../../../../screens/Home/util";
import { fmtDecimal } from "../../../../../utils/formatNumbers";

export interface PreviewPanelProps {
  loading: boolean;
  setMode: Function;
  balance: string;
  currency: string;
}

export const PreviewPanel = ({ loading, setMode, balance, currency }: PreviewPanelProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const totalTransactionVolume = calculateTotalTransactionVolume(
    values?.amount,
    Number(values?.programWalletIds?.groupWalletCount)
  );

  return (
    <>
      <UmojaPaymentWidgetHeader
        setMode={setMode}
        mode="disburse"
        headerTitle={`${t("preview_disbursement")}`}
      />
      <div>
        <div className="padding-5">
          <div className="payment__disbursement__wallet-summary">
            <ReactSVG src="/disburseActive.svg" />
            <div style={{ width: "100%", height: "100%" }}>
              <p className="no-bottom-margin payment__disbursement-review--text">
                {values?.programWalletIds?.groupName}
              </p>
              <div className="payment__disbursement-review--subtext">
                <p className="no-bottom-margin">
                  {values?.programWalletIds?.groupWalletCount} {t("wallets")}
                </p>
                <div className="payment__disbursement-review--subtext">
                  <p className="no-bottom-margin">
                    {currency}{fmtDecimal(values?.amount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="summary-disbursement">
        <p className="no-bottom-margin grey-text font-weight-600 font-size-15">
          {t("sending")} <span
            className="dark-text">
            {values?.programWalletIds?.groupWalletCount} {t("wallets")}
          </span>
        </p>
        <ReactSVG
          src="/arrow.svg"
          className="margin-horizontal-10"
        />
        <p className="no-bottom-margin grey-text font-weight-600 font-size-15">
          <span className="dark-text">
            {currency}{fmtDecimal(values?.amount)}
          </span> {t("each")}
        </p>
      </div>
      <div>
        <div className="padding-5">
          <div>
            <h3 className="no-bottom-margin mt-6">{t("send_to")}</h3>
            <p className="grey-text font-weight-600 font-size-15">
              {values?.programWalletIds?.groupName}   {values?.programWalletIds?.groupWalletCount} {t("wallets")}
            </p>
          </div>
          <div>
            <h3 className="no-bottom-margin">
              {t("total_send")}
            </h3>
            <p className="grey-text font-weight-600 font-size-15">
              {currency}{fmtDecimal(totalTransactionVolume)}
            </p>
          </div>
          <div>
            <h3 className="no-bottom-margin">
              {t("new_master_wallet_balance")}
            </h3>
            <p className="grey-text font-weight-600 font-size-15">
              {currency}{fmtDecimal(getMasterWalletBalance(totalTransactionVolume, balance))}
            </p>
          </div>

          <Button
            type="submit"
            text={t("send_funds")}
            fill
            minimal
            className="disbursement__details-button"
            loading={loading}
            disabled={Number(getMasterWalletBalance(totalTransactionVolume, balance)) < 0}
          />
        </div>
      </div>
    </>
  );
};
