export const mapCurrencySign: Record<string, string> = {
  'USD': '$',
  'EUR': '€',
  'KES': 'KES',
  'HTG': 'G',
  "VUV": "VT",
  "MXN": "Mex$",
  "NGN": "₦",
  "UGX" : "USh", 
  "RWF": "RWF",
  "GHS": "GH₵",
  "TZS": "TSh",
  "ZMW" : "ZK",
  "KRW" : "₩",
  "ZAR":  "R",
  "BGN" : "лв",
  "RON": "lei",
}

export const currencyList: Array<{ label: string, value: string }> = [
            { label: "Kenyan Shillings", value: "KES" },
            { label: "Haitian Gourde", value: "HTG" },
            { label: "United States Dollar", value: "USD" },
            { label: "European Union Euro", value: "EUR" },
            { label: "Vatu", value: "VUV" },
            { label: "Mexican Peso", value: "MXN" },
            { label: "Nigeria Naira", value: "NGN" },
            { label: "Uganda Shilling", value: "UGX" },
            { label: "Tanzanian Shilling", value: "TZS" },
            { label: "South African Rand", value: "ZAR" },
            { label: "Rwandan Franc", value: "RWF" },
            { label: "Ghanaian Cedi", value: "GHS" },
            { label: "Zambia Kwacha", value: "ZMW" },
            { label: "South Korean won", value: "KRW" },
            { label: "Bulgarian Lev", value: "BGN" },
            { label: "Romanian Leu", value: "RON" },
]