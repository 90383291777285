import "../../containers/Dashboard.css";
import { Layout, UmojaHeader } from "../../components";
import { useAccount } from "../../hooks";
import { ProjectActivityCharts } from "../Home/ProgramActivityCharts";
import { FeaturedCharts } from "./FeaturedCharts";
import { Trans, useTranslation } from "react-i18next";
import DefaultProjectView from "../Home/DefaultProjectView";
import { useParams } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";
import useOrganizationPrograms from "../../hooks/useOrganizationPrograms";

type DashboardActivityScreenProps = {};

function DashboardActivityScreen(props: DashboardActivityScreenProps) {
  const params = useParams<{ organizationId: string }>();
  const account = useAccount();
  const project = useOrganizationPrograms(params?.organizationId);
  
  const { t } = useTranslation();

    if (project.isLoading) {
    return (
      <Layout.Container>
        <div className="dashboard__spinner">
          <Spinner />
        </div>
      </Layout.Container>
    )
    }
  
  return project ? (
    <Layout.Container>
      <UmojaHeader
        title={<Trans i18nKey="activity_explorer" />}
        user={account.data}
      />
      <div className="activity_explorer_wrapper">
        <FeaturedCharts />
        <ProjectActivityCharts  />
      </div>
    </Layout.Container>
  ) : (
      <Layout.Container>
        <UmojaHeader title={t('activity_explorer')} user={account.data} />
        <DefaultProjectView displayProjectHeading={false} displayPaymentWidget={false}/>
      </Layout.Container>
  );
}
export default DashboardActivityScreen;
