export const spanish = {
  // languages
  english: "Inglés",
  creole: "Criollo",
  french: "Francés",
  spanish: "Español",
  settings: "Configuración",
  good_day: "Buen día",
  good_evening: "Buenas noches",
  step_one: "Paso 1",
  step_two: "Paso 2",
  step_three: "Paso 3",
  step_four: "Paso 4",
  next_step: "Siguiente",
  section_complete: "Sección completa",
  section_incomplete: "Sección incompleta",
  update: "Actualización",
  save: "Guardar",
  dismiss: "Descartar",
  phone_number: "Número de teléfono",
  // Account Settings
  account_information: "Información de la cuenta",
  account_information_subtitle:
    "Vea la información sobre su cuenta y su estado actual",
  name: "Nombre",
  email_address: "Dirección de correo electrónico",
  edit: "Editar",
  change_your_password: "Cambiar la contraseña",
  current_password: "Contraseña actual",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  confirm_new_password:
    "La contraseña de confirmación debe ser la misma que la contraseña nueva",
  change_password_any_time: "Cambie su contraseña en cualquier momento",
  new_project_description:"Los usuarios del Tablero Umoja con permisos de administrador pueden crear nuevos Programas y desembolsar fondos en la Red Umoja",
  ok: "Vale",
  // Project Management
  create_a_project: "Crear un proyecto",
  create_a_project_subtitle: "Crea tu primer espacio de Programa",
  project_title: "Título del programa",
  project_type: "Tipo de programa",
  project_focus: "Enfoque del programa",
  project_language: "Idioma del programa",
  add_more_participants: "Añadir más participantes",
  enter_project_title: "introduzca el título del proyecto",
  select_type: "Seleccione el tipo",
  select_focus: "seleccionar foco",
  continue_when_ready: "Continúe cuando esté listo",
  complete_previous_steps: "Complete los pasos anteriores",
  project_details: "Detalles del programa",
  project_details_subtitle: "Definir localidades y monedas del programa",
  project_localization: "Localización del proyecto",
  operating_country: "País de operación",
  project_currency: "Moneda del programa",
  project_goals: "Objetivos del programa",
  target_disbursement_amount: "Cantidad de desembolso objetivo",
  target_merchant_enrollment: "Objetivo de inscripción de comerciantes",
  target_participant_enrollment: "Objetivo de inscripción de destinatarias",
  target_percent_expenditure: "Objetivo de porcentaje de fondos a gastar",
  enroll_participants: "Inscribir Participantes",
  enroll_participants_subtitle:
    "Añadir beneficiarios y comerciantes a tu nuevo programa",
  upload_from_kobo: "Importar datos de Kobotoolbox",
  upload_data: "Subir datos",
  upload_custom_excel: "Subir Excel personalizado",
  import_data_from_koboToolbox: "Importar datos de KoboToolbox",
  select_data_source: "Seleccione una Fuente de Datos",
  browse: "Navegar",
  participant_group: "Grupo de beneficiarios",
  merchant_group: "Grupo de comerciantes",
  ready_to_deploy: "Listo para desplegar",
  ready_to_deploy_subtitle:"Revise los detalles y prepárese para ejecutar su programa",
  ready_to_launch: "Listo para el lanzamiento",
  everything_checks_out: "Todo está comprobado",
  launch_project: "Lanzar programa",
  category_header: "Crear hasta 16 categorías",
  category_detail:"Los comerciantes utilizan las categorías para crear artículos en sus tiendas. Umoja utiliza estos datos para revelar la información del programa",
  category_input_placeholder: "Introduce una categoría de artículo",
  cash_out_day: "Día de cobro",
  select_cash_out: "Seleccione el día de cobro",
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  health: "Salud",
  nutrition: "Nutrición",
  food_security: "Seguridad alimentaria",
  cash_assistance: "Asistencia en efectivo",
  voucher_assistance: "Asistencia con vales",
  housing_shelter: "Vivienda/Alojamiento",
  water_and_sanitation: "Agua y saneamiento",
  livelihood_and_economic_support: "Medios de vida/apoyo económico",
  education: "Educación",
  gender_equality: "Igualdad de género",
  humanitarian_relief: "Ayuda humanitaria",
  development: "Programa de desarrollo",
  microFinance: "Programa de microfinanciación",
  other: "Otro",
  private_sector: "Programa del sector privado (empresarial)",
  create_first_project: "Crear el primer espacio del programa",
  setup_locality: "Definir localidades y monedas del programa",
  define_product: "Definir las categorías de productos",
  enroll_participant: "Inscribir a los participantes y comerciantes",
  create_project: "Crear un programa",
  wallet_groups: "Grupos de carteras",
  select_wallet: "Seleccione un grupo de carteras",
  create_new_wallet: "Crear grupo",
  manage_wallet_group: "Gestionar grupos de carteras",
  group_name: "Nombre del grupo",
  wallet_group: "Grupos de carteras",
  search_participants: "Buscar participantes para añadirlos a los grupos",
  delete_group: "Eliminar grupo",
  add_to_list: "Añadir a la lista",
  create_group: "Crear grupo",
  create_group_inner_text: "Buscar participantes para añadirlos a este grupo",
  selected: "Seleccionado",
  select: "Seleccione",
  remove_from_list: "Eliminar de la lista",
  program_localization: "Localización de programas",
  project_categories: "Categorías de programas",
  create_group_success: "Grupo creado con éxito",
  create_group_error: "Error al crear el grupo",
  delete_group_success: "Grupo eliminado con éxito",
  delete_group_error: "Error al borrar el grupo",
  update_group_success: "Error al actualizar el grupo de carteras",
  update_group_error: "Billeteras actualizadas con éxito",
  save_changes: "guardar los cambios",
  FIRST_NAME_REQUIRED: "El nombre es obligatorio",
  LAST_NAME_REQUIRED: "Se requiere el apellido",
  AGE_GROUP_BETWEEN_0_6: "El grupo de edad debe estar entre 0 y 6",
  PHONE_NUMBER_REQUIRED: "Se requiere el número de teléfono",
  PHONE_NUMBER_INVALID: "El número de teléfono no es válido para este país",
  PHONE_NUMBER_ALREADY_IN_PROJECT: "El número de teléfono ya existe en este proyecto",
  PHONE_NUMBER_ALREADY_IN_DB: "El número de teléfono ya existe en otro proyecto",
  PHONE_NUMBER_FILE_DUP: "El número de teléfono está duplicado en el archivo",
  PHONE_NUMBER_ALREADY_IN_MONEY_API: "El número de teléfono ya está registrado en Money API",
  INVALID_GENDER: "Datos ingresados no válidos",
  age_group: "Grupo de edad",
  gender: "Género",
  delete_dialog_message: "¿Está seguro de querer eliminar este grupo? Esto no se puede deshacer",
  cancel: "Cancelar",
  delete: "Borrar",
  group_name_error: "El nombre del grupo no puede superar los 20 caracteres",
  clear: "Borrar",
  category_too_long: "La categoría sólo puede tener 20 caracteres",
  operation_country_validation: "Se requiere el país de operación",
  category_validation: "Introduzca al menos 1 categoría",
  program_currency_validation: "Se requiere la moneda del programa",
  program_language_validation: "Se requiere el idioma del programa",
  only_valid_number_validation: "Sólo se requiere un número válido",
  only_valid_number_btn_one_and_hundred: "Sólo se permite un número válido entre 1 y 100",
  valid_email_validation: "se requiere un correo electrónico válido",
  project_focus_validation: "Se requiere el enfoque del proyecto",
  project_title_validation: "Se requiere el título del proyecto",
  project_type_validation: "Se requiere el tipo de proyecto",
  email_required_validation: "Se requiere un correo electrónico",
  valid_email_required_validation: "Por favor, introduzca una dirección de correo electrónico válida",
  password_required_validation: "Por favor, introduzca una contraseña válida",
  confirm_password_validation: "La contraseña confirmada debe ser la misma que la nueva contraseña",
  new_password_confirmation_validation: "La nueva contraseña no puede ser la misma que su contraseña actual",
  recipient_upload_template: "Plantilla de carga del destinatario",
  merchant_upload_template: "Plantilla de carga del comerciante",
  whitelist_user: "Usuarios de la lista blanca",
  view_whitelist_user: "Ver usuarios de la lista blanca",
  whitelist_information: "A continuación se muestra una lista de participantes que han sido cargados y puestos en la lista blanca para su programa. Para completar su inscripción, comparta el código de su programa con ellos y asegúrese de que acceden a la plataforma utilizando el número de teléfono que aparece aquí.",
  whitelist_data: "DATOS DE LA LISTA DE BLANCOS",
  enrollment_status: "Estado de las inscripciones",
  enrolled: "Inscrito",
  not_enrolled: "No inscrito",
  remove_all_wallets_dialog_text: "Está intentando eliminar todos los monederos esto eliminará automáticamente el grupo. Esta acción no se puede deshacer. ¿Está seguro de que quiere proceder?",
  discard_changes_title: "¿Seguro que quieres dejarlo?",
  discard_changes_message: "Parece que estás en medio de una tarea y no has guardado tu trabajo. Si te vas antes de guardar, tus cambios se perderán",
  discard_changes_button: "Descartar cambios",
  stay: "Stay",
  // Organization Settings
  organization_information: "Información de la organización",
  organization_information_subtitle:
    "Ver información sobre su organización y equipo",
  organization_name: "Nombre de la organización",
  permission_level: "Nivel de permiso",
  manage_team_members: "Gestionar los miembros del equipo",
  manage_team_members_subtitle:
    "Ver los miembros actuales del equipo, añadir nuevos miembros del equipo y actualizar el estado de los miembros",
  invite_team_member: "Invitar a un miembro del equipo",
  access_level: "Nivel de acceso",
  date_added: "Fecha de adición",
  email_sent_successfully: "Correo electrónico enviado con éxito",
  email_already_exist: "La cuenta ya está en la organización",
  error_completing_request: "Error al completar su solicitud",
  email_to_send_invite:
    "¿A qué dirección de correo electrónico desea enviar una invitación al equipo?",
  valid_email_required:
    "Se requiere una dirección de correo electrónico válida",
  confirm: "Confirmar",
  back: "Atrás",

  // Settings Sidebar
  your_account_settings: "Configuración de la cuenta",
  organization_settings: "Configuración de la organización",
  project_management: "Gestión de proyectos",
  additional_resources: "Recursos adicionales",
  support: "Soporte técnico",
  help_center: "Centro de Ayuda",
  // Team
  team: "Equipo",
  role: "Rol",
  // Wallet Explorer
  wallet_explorer: "Explorador de carteras",
  merchant: "Comerciante",
  Merchant: "Comerciante",
  recipient: "Destinataria",
  Recipient: "Destinataria",
  from: "Desde",
  to: "A",
  status: "Estado",
  txn_type: "Tipo de Txn",
  program_code: "Código de programa",
  Program: "Programa",
  program_management: "Gestión del programa",
  enrollment_date: "Fecha de inscripción",
  account_status: "estado de la cuenta",
  last_active: "Última activa",
  user_type: "Tipo de Usuario",
  total_volume: "Volumen de transacciones",
  program_no: "Número de programa",
  total_transactions: "Total de transacciones",
  card_reference: "Tarjeta",
  program : "Programa",
  pin_code: "Código PIN",
  transaction_explorer: "Explorador de Transacciones",
  edit_data: "Editar datos",
  register_card: "Registrar Tarjeta",
  unregister_card: "Desregistrar la tarjeta",
  export_data: "Exportar dato",
  resume_wallet: "Reanudar la billetera",
  pause_wallet: "Pausar billetera",
  activity_explorer: "Explorador de actividad",
  Total: "Total",
  transaction_status: "Estado de la transacción",
  transaction_type: "Tipo de transacción",
  timestamp: "fecha",
  time:"tiempo",
  Disburse_to: "Desembolsar a",
  Disburse: "Desembolsar",
  no_record: "Sin registro",
  average_transactions: "Transacciones medias",
  offline_transactions: "Transacciones fuera de línea",
  total_enrolled: "Total de inscripciones",
  merchants: "Comerciantes",
  register_program_card: "Registrar tarjeta de programa",
  register_card_confirm:
    "Haga clic en Registrar tarjeta para vincular una tarjeta Umoja al monedero de <strong>{{ wallet }}</strong>",
  unregister_card_confirm:
    "¿Está seguro de que quiere anular el registro de esta tarjeta Umoja del monedero de <strong>{{ wallet }}</strong>?",
  confirm_role_change: "¿Estás seguro de que quieres cambiar el papel de <strong>{{ user }}</strong> de <strong>{{ oldRole }}</strong> a <strong>{{newRole}}</strong>?",
  same_role_change:  "<strong>{{ user }}</strong> ya tiene el privilegio de <strong>{{newRole}}</strong>",
  successfully_unregistered_card: "Se ha anulado con éxito el registro de",
  more: "Más",
  search: "Buscar",
  no_available_cards: "No hay tarjetas disponibles.",
  Completed: "Completado",
  Pending: "Pendiente",
  Processing: "Procesando",
  Failed: "Fallido",
  DISBURSEMENT: "DESEMBOLSO",
  WITHDRAWAL: "RETIRADA",
  PURCHASE: "COMPRA",
  REFUNDED: "COMPRA",
  REFUND: "REEMBOLSO",
  wallet_search: "Buscar el nombre o el número de teléfono",
  // Dashboard
  enrollments: "Inscripción",
  track_activity: "Seguimiento de la actividad",
  view_wallets: "Ver los monederos",
  activity: "Actividad",
  wallets: "Cartera",
  transaction_volume: "Volumen de transacciones",
  global_operations: "Operaciones globales",
  project: "Proyecto",
  offline_online_transaction: "Transacciones en línea / fuera de línea",
  online: "en línea",
  offline: "fuera de línea",
  age_group_breakdown: "Desglose por edades",
  admin: "Administrador",
  viewer: "Observador",
  organizer: "Organizador",
  Admin: "Administrador",
  Viewer: "Observador",
  Organizer: "Organizador",
  logout: "Cerrar la sesión",
  transactions: "Transacciones",
  participant_pulse: "Pulso del participante",
  flow_of_funds: "Flujo de fondos",
  merchant_wallet_balance: "Saldo medio de la cartera del comerciante",
  total_disbursed: "Total desembolsado",
  total_refunded: "Total reembolsado",
  recipient_wallet_balance: "Saldo medio de la cartera del destinatario",
  total_spent: "Total gastado",
  demographic_insight: "Información demográfica",
  spending_by_gender: "Categoría de productos (gasto por género)",
  no_product_category: "Categoría de producto no añadida al proyecto",
  spending_pattern: "Patrón de gasto",
  volume_transacted: "Volumen negociado",
  total_txns: "Txns Totales",
  average_txn: "Txn medio",
  getting_started: "Cómo empezar",
  welcome_to_umoja: "Bienvenido a la suite de gestión de Umoja",
  project_info:
    "Todavía no hay proyectos activos en tu espacio de trabajo. Mientras tanto, te animamos a obtener más información visitando el centro de ayuda.",
  last_7_days: "Ultimo 7 días",
  programme_home: "Inicio de la gestión",
  no_data: "Sin datos",
  volume: "Volumen",
  // Payment Widget
  disburse: "Desembolsar",
  disbursement_amount: "Importe de los desembolsos",
  disburse_to: "Desembolado a",
  funded_by: "Financiado por",
  all_wallets: "Toda los monederos",
  primary_wallet: "Monedero principal",
  total_disbursement: "Desembolso total",
  preview_disbursement: "Vista previa del desembolso",
  all_recipients: "Toda los Destinatarios",
  recipients: "Destinatarios",
  all_merchants: "Toda los Comerciantes",
  wallet_balance: "Saldo de la cartera",
  disburse_funds: "Desembolsar fondos",
  latest_disbursements: "Últimos desembolsos",
  amount: "Importe",
  send_cash_to_wallet: "Enviar efectivo a carteras",
  master_wallet: "Billetera maestra",
  master_wallet_balance: "Saldo principal de Wallet",
  select_target_group: "Seleccionar grupo objetivo",
  no_funds: "No hay fondos disponibles para desembolsar",
  please_endure: "Por favor, asegúrese de que el monedero principal de su proyecto ha sido financiado por su organización",
  get_started: "Empieza creando tu primer proyecto",
  sent: "Enviado",
  received: "Recibido",
  wallet_received: "Cartera recibida",
  sent_to: "Enviado a",
  send_to: "Enviar a",
  sending: "Enviando",
  total_send: "Envío total",
  new_master_wallet_balance: "Nuevo saldo de billetera principal",
  send_funds: "Enviar fondos",
  each: "cada",
  no_recent_activity: "No hay actividad reciente",
  success_transaction: "Transacción completada con éxito",
  failed_transaction: "Error al completar la transacción",
  // sidebar
  home: "Inicio",
  alerts: "Alertas",
  // breadcrumb
  account_settings: "Configuración Cuenta",
  // Additional resource page
  platform_news: "Novedades de la plataforma",
  legal: "Aviso legal",
  company_support: "Empresa y apoyo",
  management_status: "Estado de la gestión",
  umoja_commerce: "Comercio Umoja",
  umoja_wallet: "Billetera Umoja",
  umoja_card: "Tarjeta Umoja",
  terms_of_service: "Condiciones de servicio",
  privacy_policy: "Política de privacidad",
  GDPR_privacy_policy: "Política de privacidad y GDPR",
  emerging_impact_services: "Servicios de impacto emergente",
  umoja_brand_kit: "Kit de marca Umoja",
  ecosystem_case_studies: "Estudios de caso del ecosistema",
  help_center_support: "Centro de ayuda y soporte",
  careers: "Carreras",
  // Non logged-in pages
  login_page_description:
    "Umoja es una plataforma unificada para pagos digitales y pagos en los mercados emergentes.",
  sign_into_your_account: "Acceda a su cuenta",
  learn_more_about_umoja: "Más información sobre Umoja",
  contact_support: "Contacta con el servicio de asistencia",
  having_trouble_signin: "¿Tiene problemas para iniciar sesión?",
  sign_in: "Iniciar sesión",
  your_email: "Su correo electrónico",
  password: "Contraseña",
  forgot_password: "Olvidé mi contraseña",
  submit: "Enviar",
  email: "Correo electrónico",
  email_required: "Se requiere un correo electrónico",
  valid_email: "Introduzca una dirección de correo electrónico válida",
  password_required: "Se requiere contraseña",
  password_length_validation: "La contraseña debe tener al menos 8 caracteres",
  reset_password: "Restablecer contraseña",
  // Umoja toast and alerts
  problem_making_request: "Hubo un problema al hacer una solicitud",
  disbursement_permission_error:
    "Debes ser al menos un organizador para desembolsar fondos",
  invalid_credentials_error: "Correo electrónico/contraseña no válidos",
  signin_error: "Hubo un problema al iniciar sesión",
  password_changed_successfully: "La contraseña ha sido cambiada con éxito",
  error_submitting_request: "Hubo un problema al enviar su solicitud",
  problem_creating_updating_project: "Hubo un problema al crear o actualizar su proyecto",
  creating_updating_project_successful: "¡El proyecto se ha actualizado con éxito!",
  role_changed_successfully: "El rol ha cambiado con éxito",
  request_not_completed:
    "Esta solicitud no se ha podido completar, inténtelo de nuevo",
  problem_adding_details: "Hubo un problema al añadir detalles a su proyecto",
  successfully_enrolled_users: "Usuarios inscritos con éxito",
  error_uploading_excel_file: "Error al cargar el archivo .excel",
  review_imported_data: "Revisar datos importados",
  imported_data: "Datos importados",
  verify_imported_data_text:
    "Verifique que esta tabla de datos muestre las columnas correctas. Umoja utilizará este formato para generar análisis con los datos de su programa.",
  remove: "Retirar",
  yes_correct: "¡Si eso es correcto!",
  merchants_uploaded_successfully: "Comerciantes cargados con éxito",
  participants_uploaded_successfully: "Participantes cargados con éxito",
  problem_publishing_project: "Hubo un problema al publicar su proyecto",
  wallet_updated_successfully: "Cartera actualizada con éxito",
  wallet_paused_successfully: "Cartera pausada con éxito",
  wallet_resumed_successfully: "Cartera reanudada con éxito",
  please_try_again: "Por favor, inténtelo de nuevo",
  register_cards_permission_error:
    "Lo sentimos, sólo los organizadores y administradores de proyectos pueden registrar tarjetas",
  problem_registering_umoja_card:
    "Hubo un problema al registrar esta tarjeta Umoja",
  successfully_registered_card: "Tarjeta Umoja registrada con éxito",
  error_updating_email_address:
    "Hubo un error al actualizar tu dirección de correo electrónico",
  successfully_updated_password: "¡Contraseña actualizada con éxito!",
  successfully_updated_email:
    "¡Correo electrónico actualizado con éxito! Compruebe su bandeja de entrada para confirmarlo",
  error_updating_password:
    "Hubo un error al actualizar su contraseña de correo electrónico",

  // demographics insight data
  female_total_transactions: "MUJER",
  male_total_transactions: "MASCULINA",
  lgbtq_total_transactions: "LGBTQ",
  other_total_transactions: "OTRAS",

  // redeem invite
  first_name: "Nombre",
  last_name: "Apellido",
  redeem_invite: "Canjear invitación",
  successful: "¡Éxito!",
  enter_first_name: "Introduzca su nombre",
  enter_last_name: "Introduzca su apellido",
  first_name_required: "Se requiere un nombre",
  last_name_required: "Se requiere un apellido",
  only_letters_allowed: "Sólo se permiten letras sin espacios",
  password_must_match: "Las contraseñas deben coincidir",
  character_is_too_short: "El carácter es demasiado corto",

  next : "Siguiente",
  previous: "Anterior",
  first: "Primero",
  page: "Página",
  of: "de",
  last: "Última",
  active: "Activo",
  paused: "Pausado",
  reset: "Reiniciar",
  sort_asc: "Ordenación ascendente",
  sort_desc: "Ordenación descendente",
  sort_A:"Ordenar A-Z",
  sort_z:"Ordenar Z-A",

  //Forget Password
  forgot_password_header : "Restablecimiento de la contraseña",
  email_containing_a_link : "Se le ha enviado un correo electrónico con un enlace para restablecer su contraseña",
  forgot_password_wrapper_text:  "¿No has recibido el correo?",
  forgot_password_wrapper_option_header: "Si no has recibido el correo en 5 minutos, puede haber ocurrido una de las siguientes cosas:",
  forgot_password_wrapper_option_text_one: "El correo electrónico está en tu carpeta de spam/basura",
  forgot_password__wrapper__option_text_two: "No podemos entregar el correo electrónico a la dirección proporcionada. (Por lo general, debido al cortafuegos o al filtrado de la empresa)",
  close: "cerrar",
  
  //whatsapp Login
  pin_required: "El PIN es necesario",
  pin_must_be_at_least: "El PIN debe tener al menos 4 caracteres numéricos",
  pin_validated_successfully: "Pin de Umoja validado con éxito",
  pin_invalid: "PIN inválido" ,
  error_validating_pin: "Error al validar el PIN",
  pin_validated_successfully_continue_in_chat: "Umoja PIN validado con éxito por favor continúe en el chat de whatsapp",

  enter_pin_code: "Introduzca su código PIN para acceder",
  confirm_pin_code: "Confirma tu código PIN",
  
  confirmPinCode: "Confirme el código PIN",
  confirm_pin: "Confirme el código PIN",
  reset_pin: "Restablecer el código PIN",
  new_pin_code: "Nuevo código PIN",
  invalid_or_expired: "Token inválido o caducado",
  invalid_pin: "Este es un código PIN inválido. Por favor, introduzca el PIN actual para continuar",
  invalid_pin_format: "Formato de PIN inválido",
  current_pin_code: "PIN actual",
 

  //whatsapp Cambiar Pin
  current_pin_error: "El PIN actual y el nuevo PIN no pueden ser el mismo",
  new_pin_error: "El nuevo código PIN debe coincidir con el código PIN confirmado",
  pin_change_successfully: "Cambio de PIN de Umoja con éxito",
  error_submitting_pin_change: "Error al enviar el cambio de PIN, por favor, inténtelo de nuevo",
  pin_change_successfully_continue_in_chat: "Cambio de PIN de Umoja con éxito, por favor, continúe en el chat de whatsapp",
  organizations: "Organismos",
  revoke_access: "Revocar acceso",
  revoke_access_confirmation: "Are you sure you want to revoke access to <strong> {{ organization }} </strong>?",
  activate_organization: "Activate Organization",
  activate_organization_confirmation: "Are you sure you want to enable access for <strong> {{ organization }} </strong>?",
  organization_activated_successfully: "Acceso de la organización activado con éxito",
  organization_revoked_successfully: "Organización El acceso fue revocado con éxito",
  organization_revoke_failed: "Revoking organization access failed",
  organization_activate_failed: "Activating organization access failed",
  activate: "Active",
  add_money: "Añadir dinero",
  organization_type: "Tipo de organización",
  action: "Acción",
  customer_email: "Email del cliente",
  create_new_organization: "Crear nueva organización",
  create_organization: "Crear nueva organización para Umoja",
  create_organization_submit: "Crear organización",
  organization_details: "Detalles de la organización",
  program_information: "Información del programa",
  administrator_information: "Información del administrador",
  organization_created_successfully: "Organización creada con éxito",
  error_creating_organization: "Hubo un problema al crear su organización",
  organization_name_input_placeholder: "Introduzca el nombre de la organización",
  organization_name_input_label: "Nombre de la organización",
  organization_name_input_required: "El nombre de la organización es obligatorio",
  organization_type_input_placeholder: "Introduzca el tipo de organización",
  organization_type_input_label: "Tipo de organización",
  organization_type_input_required: "El tipo de organización es obligatorio",
  organization_billing_email_input_placeholder: "Introduzca el correo electrónico de facturación",
  organization_billing_email_input_label: "Correo electrónico de facturación",
  organization_billing_email_input_required: "El correo electrónico de facturación es obligatorio", 
  organization_contact_email_input_placeholder: "Introduzca el correo electrónico de contacto",
  organization_contact_email_input_label: "Correo electrónico de contacto",
  organization_contact_email_input_required: "El correo electrónico de contacto es obligatorio",
  first_name_input_placeholder: "Introduzca el nombre",
  first_name_input_label: "Nombre",
  first_name_input_required: "El nombre de pila es obligatorio",
  last_name_input_placeholder: "Introduzca el apellido",
  last_name_input_label: "Apellido",
  last_name_input_required: "El apellido es obligatorio",
  select_country: "Seleccione el país",
  select_language: "Seleccione el idioma",
  select_currency: "Seleccionar moneda",
  account_deactivated_error: "Su cuenta ha sido desactivada. Póngase en contacto con el equipo de ventas",
  suspended: "Suspendido",
  add_organization: "Añadir organización",
};
