import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { useTranslation } from "react-i18next";

export const EmailInput = () => {
  const { t } = useTranslation();
  const validateInput = (email: string) => {
  if (email.length < 1 || !isValidEmail(email)) {
    return t("valid_email_required");
  }

  return false;
};
  const EmailInputInstance = useField("invite_email", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const { meta, getInputProps } = EmailInputInstance;

  return (
    <div>
      <TextInput
        {...getInputProps()}
        type="email"
        placeholder="chadwick@wakanda.io"
      />
      {meta.isTouched && (
        <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
          {meta?.error}
        </p>
      )}
    </div>
  );
};

// https://codesandbox.io/s/react-form-demo-q9mgm?file=/src/index.js:4838-5082
function isValidEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
