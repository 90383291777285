export const english = {
  // languages
  english: "English",
  creole: "Creole",
  french: "French",
  spanish: "Spanish",
  settings: "Settings",
  good_day: "Good day",
  good_evening: "Good evening",
  step_one: "Step 1",
  step_two: "Step 2",
  step_three: "Step 3",
  step_four: "Step 4",
  next_step: "Next",
  section_complete: "Section complete",
  section_incomplete: "Incomplete Section",
  update: "Update",
  save: "Save",
  dismiss: "Dismiss",
  phone_number: "Phone Number",

  // Account Settings
  account_information: "Account information",
  account_information_subtitle:
    "See information about your account and its current status",
  name: "Name",
  email_address: "Email address",
  edit: "Edit",
  change_your_password: "Change your password",
  current_password: "Current password",
  new_password: "New password",
  confirm_password: "Confirm Password",
  confirm_new_password: "Confirm password must be the same as New Password",
  change_password_any_time: "Change your password at any time",
  okay: "Okay",
  // Project Management
  create_a_project: "Create a program",
  create_a_project_subtitle: "Create your first Program space",
  project_title: "Program Title",
  project_type: "Program Type",
  project_focus: "Program Focus",
  project_language: "Program Language",
  add_more_participants: "Add More Participants",
  enter_project_title: "Enter Project Title",
  select_type: "Select Type",
  select_focus: "Select Focus",
  continue_when_ready: "Continue when you’re ready",
  complete_previous_steps: "Complete previous steps",
  project_details: "Program Details",
  project_details_subtitle: "Define localities & program currencies",
  project_localization: "Program Localization",
  operating_country: "Operating Country",
  project_currency: "Program Currency",
  project_goals: "Program goals",
  project_categories: "Program Categories",
  category_header: "Create up to 16 Category",
  category_detail:
    "Merchants use categories to create items in their shops. Umoja uses this data to reveal program insights",
  target_disbursement_amount: "Target disbursement amount",
  target_merchant_enrollment: "Target merchant enrollment",
  target_participant_enrollment: "Target recipient enrollment",
  target_percent_expenditure: "Target % of funds to spend",
  enroll_participants: "Enroll Participants",
  enroll_participants_subtitle:
    "Add program recipients and merchants to your new Program",
  upload_from_kobo: "Import data from Kobotoolbox",
  upload_data: "upload data",
  upload_custom_excel: "Upload custom excel",
  import_data_from_koboToolbox: "Import data from KoboToolbox",
  select_data_source: "Select a Data Source",
  browse: "browse",
  participant_group: "Recipient group",
  merchant_group: "Merchant group",
  ready_to_deploy: "Ready to deploy",
  ready_to_deploy_subtitle: "Review details and prepare to run your Program",
  ready_to_launch: "Ready to launch",
  everything_checks_out: "Everything checks out",
  launch_project: "Launch program",
  category_input_placeholder: "Enter an item category",
  create_first_project: "Create first program space",
  setup_locality: "Define localities & program currencies",
  define_product: "Define Product Categories",
  enroll_participant: "Enroll Participant and Merchants",
  create_project: "Create Program",
  new_project_description:
    "Umoja Dashboard users with admin permissions can create new Programs and disburse funds on the Umoja Network",
  cash_out_day: "Cash out Day",
  select_cash_out: "Select Cash Out Day",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  cash_out_validation_error: "Cash Out Day is required",
  wallet_groups: "Wallet Group",
  select_wallet: "Select a Wallet Group",
  create_new_wallet: "Create New Wallet Group",
  manage_wallet_group: "Manage Wallet Groups",
  group_name: "Group Name",
  wallet_group: "Wallet Groups",
  search_participants: "Search for participants to add to groups",
  delete_group: "Delete Group",
  add_to_list: "Add to List",
  create_group: "Create Group",
  create_group_inner_text: "Search for Participants to add them to this Group",
  selected: "Selected",
  select: "Select",
  remove_from_list: "Remove from List",
  program_localization: "Program Localization",
  create_group_success: "Group created successfully",
  create_group_error: "Error creating group",
  delete_group_success: "Delete group successfully",
  delete_group_error: "Error deleting group",
  update_group_success: "Group updated successfully",
  update_group_error: "Error updating group",
  save_changes: "Save Changes",
  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  AGE_GROUP_BETWEEN_0_6: "Age group should be between 0 and 6",
  PHONE_NUMBER_REQUIRED: "Phone number is required",
  PHONE_NUMBER_INVALID: "Phone number is invalid for this country",
  PHONE_NUMBER_ALREADY_IN_PROJECT:"Phone number already exists in this project",
  PHONE_NUMBER_ALREADY_IN_DB: "Phone number already exists in another project",
  PHONE_NUMBER_FILE_DUP: "Phone number is duplicated in the file",
  PHONE_NUMBER_ALREADY_IN_MONEY_API: "Phone number is already registered on Money API",
  INVALID_GENDER: "Invalid Data Entered",
  age_group: "Age Group",
  gender: "Gender",
  delete_dialog_message: "Are you sure want to delete this group? This can not be undone",
  cancel: "Cancel",
  delete: "Delete",
  group_name_error: "Group Name cannot exceed 20 characters",
  clear: "Clear",
  category_too_long: "Category can only be 20 character Long",
  operation_country_validation: "Operating country is required",
  category_validation: "Enter at least 1 Categories",
  program_currency_validation: "Program Currency is required",
  program_language_validation: "Program language is required",
  only_valid_number_validation: "Only valid number is required",
  only_valid_number_btn_one_and_hundred: "Only valid number between 1 and 100 is allowed",
  valid_email_validation: "a valid email is required",
  project_focus_validation: "Project focus is required",
  project_title_validation: "Project title is required",
  project_type_validation: "Project type is required",
  email_required_validation: "An email is required",
  valid_email_required_validation: "Please enter a valid email address",
  password_required_validation: "Please enter a valid password",
  password_length_validation: "Password must be at least 8 characters",
  confirm_password_validation: "Confirm password must be the same as New Password",
  new_password_confirmation_validation: "New Password cannot be the same your current password",
  recipient_upload_template: "Recipient Upload Template",
  merchant_upload_template: "Merchant Upload Template",
  account_deactivated_error: "Your account has been deactivated. Please contact the Sales Team.",
  suspended: "Suspended",
  add_organization: "Add Organization",
  whitelist_user: "Whitelisted Users",
  view_whitelist_user: "View Whitelisted Users",
  whitelist_information: "Below is a list of particiapants that have been uploaded and whitelisted for your program. To complete their enrollment, share your program code with them and ensure they access the platform using the phone number listed here.",
  whitelist_data: "WHITELIST DATA",
  enrollment_status: "Enrollment status",
  enrolled: "Enrolled",
  not_enrolled: "Not Enrolled",
  remove_all_wallets_dialog_text: "You are attempting to delete all wallets. This will automatically delete the group. This action cannot be undone. Are you sure you want to proceed?",
  discard_changes_title: "Are you sure you want to quit?",
  discard_changes_message: "It looks like you’re in the middle of a task and you haven’t saved your work. If you leave before saving, your changes will be lost.",
  discard_changes_button: "Discard Changes",
  stay: "Stay",

  // Organization Settings
  organization_information: "Organization Information",
  organization_information_subtitle:
    "See information about your organization and team",
  organization_name: "Organization name",
  permission_level: "Permission level",
  manage_team_members: "Manage team members",
  manage_team_members_subtitle:
    "See current team members, add new team members, and update member status",
  invite_team_member: "Invite a team member",
  access_level: "Access level",
  date_added: "Date added",
  health: "Health",
  nutrition: "Nutrition",
  food_security: "Food Security",
  cash_assistance: "Cash Assistance",
  voucher_assistance: "Voucher Assistance",
  housing_shelter: "Housing/Shelter",
  water_and_sanitation: "Water and Sanitation",
  livelihood_and_economic_support: "Livelihood/Economic Support",
  education: "Education",
  gender_equality: "Gender Equity",
  humanitarian_relief: "Humanitarian/Relief",
  development: "Development Program",
  microFinance: "MicroFinance Program",
  private_sector: "Private Sector (Business) Program",
  other: "Other",
  email_sent_successfully: "Email sent successfully",
  email_already_exist: "Account is already in the organization",
  error_completing_request: "Error completing your request",
  email_to_send_invite:
    "What email address would you like to send a team invite to?",
  valid_email_required: "A valid email address is required",
  confirm: "Confirm",
  back: "Back",

  // Settings Sidebar
  your_account_settings: "Your Account Settings",
  organization_settings: "Organization Settings",
  project_management: "Project Management",
  program_management: "Program Management",
  additional_resources: "Additional Resources",
  support: "Support",
  help_center: "Help Center",
  team: "Team",
  role: "Role",
  // Wallet Explorer
  wallet_explorer: "Wallet Explorer",
  merchant: "Merchant",
  Merchant: "Merchant",
  recipient: "Recipient",
  Recipient: "Recipient",
  recipients: "Recipients",
  from: "From",
  To: "To",
  status: "Status",
  txn_type: "Txn Type",
  program_code: "Program Code",
  enrollment_date: "Enrollment date",
  account_status: "Account Status",
  last_active: "Last Active",
  user_type: "User Type",
  total_volume: "Transaction Volume",
  total_transactions: "Total Transactions",
  card_reference: "Card",
  pin_code: "PIN code",
  current_pin_code: "Current PIN",
  transaction_explorer: "Transaction Explorer",
  edit_data: "Edit data",
  register_card: "Register Card",
  unregister_card: "Unregister Card",
  export_data: "Export Data",
  resume_wallet: "Resume wallet",
  pause_wallet: "Pause wallet",
  activity_explorer: "Activity Explorer",
  Total: "Total",
  transaction_status: "Transaction Status",
  transaction_type: "Transaction Type",
  timestamp: "Date",
  time: "Time",
  average_transactions: "Average Transactions",
  offline_transactions: "Offline Transactions",
  total_enrolled: "Total Enrolled",
  merchants: "Merchants",
  Disburse_to: "Disburse to",
  Disburse: "Disburse",
  register_card_confirm:
    "Click Register Card to link an Umoja Card to <strong>{{ wallet }}</strong>",
  unregister_card_confirm:
    "Are you sure you want to unregister this Umoja Card from <strong>{{ wallet }}</strong> wallet?",
  confirm_role_change: "Are you sure you want to change <strong>{{ user }}'s</strong> role from <strong>{{ oldRole }}</strong> to <strong>{{newRole}}</strong>?",
  same_role_change:  "<strong>{{ user }}</strong> already have <strong>{{newRole}}</strong> privilege",
  register_program_card: "Register Program Card",
  successfully_unregistered_card: "Successfully Unregister from",
  more: "More",
  search: "Search",
  no_available_cards: "No Available Cards",
  Completed: "Completed",
  Pending: "Pending",
  Processing: "Processing",
  Failed: "Failed",
  DISBURSEMENT: "DISBURSEMENT",
  WITHDRAWAL: "WITHDRAWAL",
  PURCHASE: "PURCHASE",
  REFUNDED: "PURCHASE",
  REFUND: "REFUND",
  wallet_search: "Search Name or Phone Number",
  // Dashboard
  enrollments: "Enrollment",
  track_activity: "Track activity",
  view_wallets: "View wallets",
  activity: "Activity",
  wallets: "Wallets",
  organizations: "Organizations",
  transaction_volume: "Transaction volume",
  global_operations: "Global Operations",
  project: "Project",
  offline_online_transaction: "Offline vs Online Transactions",
  online: "online",
  offline: "offline",
  age_group_breakdown: "Age Group Breakdown",
  admin: "Admin",
  viewer: "Viewer",
  organizer: "Organizer",
  Admin: "Admin",
  Viewer: "Viewer",
  Organizer: "Organizer",
  logout: "Log Out",
  transactions: "Transactions",
  participant_pulse: "Participant Pulse",
  flow_of_funds: "Flow of Funds",
  merchant_wallet_balance: "Average Merchant Wallet Balance",
  total_disbursed: "Total Disbursed",
  recipient_wallet_balance: "Average Recipient Wallet Balance",
  total_spent: "Total Spent",
  total_refunded: "Total Refunded",
  demographic_insight: "Demographic Insight",
  spending_by_gender: "Product Category (Spending by gender)",
  no_product_category: "Product Category not Added To Project",
  spending_pattern: "Spending Pattern",
  volume_transacted: "Volume Transacted",
  total_txns: "Total Txns",
  average_txn: "Average Tnx",
  getting_started: "Getting Started",
  welcome_to_umoja: "Welcome to the Umoja Management Suite!",
  project_info:
    "There are no active projects in your workspace yet. In the meantime, you're encouraged to learn more by visiting the Help center",
  last_7_days: "Last 7 days",
  programme_home: "Management Home",
  success_transaction: "Transaction completed successfully",
  failed_transaction: "Error completing your transaction",
  no_data: "No Data",
  volume: "volume",
  program_no: "Program No",
  program: "Program",
    // Payment Widget
  disburse: "Disburse",
  disbursement_amount: "Disbursement amount",
  disburse_to: "Disburse to",
  funded_by: "Funded by",
  all_wallets: "All Wallets",
  primary_wallet: "Primary wallet",
  total_disbursement: "Total disbursement",
  preview_disbursement: "Preview disbursement",
  all_recipients: "All Recipients",
  all_merchants: "All Merchants",
  wallet_balance: "Wallet balance",
  disburse_funds: "Disburse Funds",
  latest_disbursements: "Latest Disbursements",
  amount: "Amount",
  send_cash_to_wallet: "Send cash to wallets",
  master_wallet: "Master Wallet",
  master_wallet_balance: "Master Wallet Balance",
  select_target_group: "Select target group",
  no_funds: "No funds are available to disburse",
  please_endure:"Please ensure that your project's master wallet has been funded by your organization",
  get_started:"Get started by creating your first project",
  sent: "Sent",
  received: "Received",
  wallet_received: "Wallet Received",
  sent_to: "Sent to",
  send_to: "Send to",
  sending: "Sending",
  total_send: "Total Send",
  new_master_wallet_balance: "New Master Wallet Balance",
  send_funds: "Send Funds",
  each: "each",
  no_recent_activity: "No recent activity",
  no_record: "No Record",
  // sidebar
  home: "Home",
  alerts: "Alerts",
  // breadcrumb
  account_settings: "Account Settings",
  // Additional resource page
  platform_news: "Platform News",
  legal: "Legal",
  company_support: "Company & Support",
  management_status: "Management Status",
  umoja_commerce: "Umoja Commerce",
  umoja_wallet: "Umoja Wallet",
  umoja_card: "Umoja Card",
  terms_of_service: "Terms of Service",
  privacy_policy: "Privacy Policy",
  GDPR_privacy_policy: "GDPR & Privacy Policy",
  emerging_impact_services: "Emerging Impact Services",
  umoja_brand_kit: "Umoja Brand Kit",
  ecosystem_case_studies: "Ecosystem Case Studies",
  help_center_support: "Help Center & Support",
  careers: "Careers",
  // Non logged-in pages
  login_page_description:
    "Umoja is a unified platform for digital payments and payouts across emerging markets.",
  sign_into_your_account: "Sign into your account",

  enter_pin_code: "Enter your PIN Code to Access",
  confirm_pin_code: "Confirm your PIN Code",
  
  confirmPinCode: "Confirm PIN code",
  confirm_pin: "Confirm PIN code",
  reset_pin: "Reset PIN code",
  new_pin_code: "New PIN code",


  learn_more_about_umoja: "Learn more about Umoja",
  contact_support: "Contact Support",
  having_trouble_signin: "Having trouble signing in?",
  sign_in: "Sign in",
  your_email: "Your Email",
  password: "Password",
  forgot_password: "Forgot Password",
  submit: "Submit",
  email: "Email",
  email_required: "An email is required",
  valid_email: "Please enter a valid email address",
  password_required: "Password is required",
  reset_password: "Reset Password",
  // Umoja toasts and alerts
  problem_making_request: "There was a problem making a request",
  disbursement_permission_error:
    "You must at least be an organizer to disburse funds",
  invalid_credentials_error: "Invalid email/password provided",
  signin_error: "There was a problem signing in",
  password_changed_successfully: "Password changed successfully",
  error_submitting_request: "There was a problem submitting your request",
  problem_creating_updating_project: "There was a problem creating or updating your project",
  creating_updating_project_successful: "The project was updated successfully!",
  role_changed_successfully: "Role changed successfully",
  request_not_completed: "This request could not be completed, try again",
  problem_adding_details: "There was a problem adding details to your project",
  successfully_enrolled_users: "Successfully enrolled users",
  error_uploading_excel_file: "Error uploading excel file",
  review_imported_data: "Review Imported Data",
  imported_data: "Imported Data",
  verify_imported_data_text:
    "Please verify that this data table is displaying the correct columns. Umoja will use this format to generate analytics with your program data.",
  remove: "Remove",
  yes_correct: "Yes, this is correct!",
  merchants_uploaded_successfully: "Merchants uploaded successfully",
  participants_uploaded_successfully: "Participants uploaded successfully",
  problem_publishing_project: "There was a problem publishing your project",
  wallet_updated_successfully: "Wallet updated successfully",
  wallet_paused_successfully: "Wallet paused successfully ",
  wallet_resumed_successfully: "Wallet resumed successfully",
  please_try_again: "Please try again",
  register_cards_permission_error:
    "Sorry, only Project Organizers and Admins can register cards",
  problem_registering_umoja_card:
    "There was a problem registering this Umoja card",
  successfully_registered_card: "Successfully registered Umoja card",
  error_updating_email_address:
    "There was an error updating your email address",
  successfully_updated_password: "Successfully updated password!",
  successfully_updated_email:
    "Successfully updated email! Check your inbox to confirm",
  error_updating_password: "There was an error updating your email password",

  // demographics insight data
  female_total_transactions: "WOMEN",
  male_total_transactions: "MEN",
  lgbtq_total_transactions: "LGBTQ",
  other_total_transactions: "OTHER",

  // redeem invite
  first_name: "First Name",
  last_name: "Last Name",
  redeem_invite: "Redeem Invite",
  successful: "Successful",
  enter_first_name: "Enter your first name",
  enter_last_name: "Enter your last name",
  first_name_required: "First name is required",
  last_name_required: "Last name is required",
  only_letters_allowed: "Only letters are allowed without spaces",
  password_must_match: "Password must match",
  character_is_too_short: "Character is too short",

  next : "Next",
  previous: "Previous",
  first: "First",
  page: "Page",
  of: "of",
  last: "Last",
  paused: "Paused",
  active: "Active",
  reset: "Reset",
  sort_asc: "Sort Ascending",
  sort_desc: "Sort Descending",
  sort_A:"Sort A-Z",
  sort_z:"Sort Z-A",

  //Forget Password
  forgot_password_header : "Password Reset",
  email_containing_a_link : "An email containing a link to reset your password has been sent to you.",
  forgot_password_wrapper_text:  "Didn't get the mail?",
  forgot_password_wrapper_option_header: "If you didn't receive the mail within 5 minutes, one of the following could have happened:",
  forgot_password_wrapper_option_text_one: "The email is in your spam/junk folder.",
  forgot_password__wrapper__option_text_two: "We can't deliver the email to the address supplied. (Usually because of corporate firewall or filtering.)",
  close: "close",

  //whatsapp Login
  pin_required: "PIN is required",
  pin_must_be_at_least: "PIN must be at least 4 numeric character",
  pin_validated_successfully: "Umoja Pin Validated Successfully",
  pin_invalid: "Invalid PIN",
  invalid_pin: "This is an invalid PIN code. Please enter the current PIN to continue.",
  error_validating_pin: "Error Validating PIN",
  pin_validated_successfully_continue_in_chat: "Umoja PIN validated successfully please continue in whatsapp chat",
  invalid_or_expired: "Invalid or Expired Token",
  invalid_pin_format: "Invalid PIN Format",
 

  //whatsapp Change Pin
  current_pin_error: "Current PIN and new PIN cannot be the same",
  new_pin_error: "New PIN code must match Confirm PIN Code",
  pin_change_successfully: "Umoja PIN Change Successfully",
  error_submitting_pin_change: "Error submitting PIN change, please try again",
  pin_change_successfully_continue_in_chat: "Umoja PIN change successfully please continue in whatsapp chat",


  //Organizations
  revoke_access: "Revoke Access",
  revoke_access_confirmation: "Are you sure you want to revoke access to <strong> {{ organization }} </strong>?",
  activate_organization: "Activate Organization",
  activate_organization_confirmation: "Are you sure you want to enable access for <strong> {{ organization }} </strong>?",
  organization_activated_successfully: "Organization access activated successfully",
  organization_revoked_successfully: "Organization Access revoked successfully",
  organization_revoke_failed: "Revoking organization access failed",
  organization_activate_failed: "Activating organization access failed",
  activate: "Activate",
  add_money: "Add Money",
  organization_type: "Organization Type",
  action: "Action",
  customer_email: "Customer Email",
  create_new_organization: "Create New Organization",
  create_organization: "Create New Organization for Umoja",
  create_organization_submit: "Create Organization",
  organization_details: "Organization Details",
  program_information: "Program Information",
  administrator_information: "Administrator Information",
  organization_created_successfully: "Organization created successfully",
  error_creating_organization: "There was a problem creating your organization",
  organization_name_input_placeholder: "Enter Organization Name",
  organization_name_input_label: "Organization Name",
  organization_name_input_required: "Organization name is required",
  organization_type_input_placeholder: "Enter Organization Type",
  organization_type_input_label: "Organization Type",
  organization_type_input_required: "Organization Type is required",
  organization_billing_email_input_placeholder: "Enter Billing Email",
  organization_billing_email_input_label: "Billing Email",
  organization_billing_email_input_required: "Billing Email is required", 
  organization_contact_email_input_placeholder: "Enter Contact Email",
  organization_contact_email_input_label: "Contact Email",
  organization_contact_email_input_required: "Contact Email is required",
  first_name_input_placeholder: "Enter First Name",
  first_name_input_label: "First Name",
  first_name_input_required: "FirstName Name is required",
  last_name_input_placeholder: "Enter Last Name",
  last_name_input_label: "Last Name",
  last_name_input_required: "Last Name is required",
  select_country: "Select Country",
  select_language: "Select Language",
  select_currency: "Select Currency",
    
  };
