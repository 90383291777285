import React, { FunctionComponent } from "react";
import { RecipientWallet } from "../../components/Widgets/PaymentWidget/panels/TargetGroupPanel";
import WalletGroupButton from "./WalletGroupButton";


interface GroupInfoProps {
  groups: RecipientWallet[],
  // setCreateView: Function
  activeGroup: RecipientWallet | null,
  setActiveGroup: Function
  // setListModified: Function
  setGroupNameEdit: Function
  groupNameUpdated: boolean
}

const GroupInfo: FunctionComponent<GroupInfoProps> = ({ groups, activeGroup, setActiveGroup, setGroupNameEdit, groupNameUpdated}: GroupInfoProps) => {
  return (
     <div style={{ height: "79%", overflow: 'scroll', width: "400px", paddingBottom: "20px"}}>
      { groups?.map(( group: RecipientWallet ) => (
        <WalletGroupButton
          key={group.groupId}
          group={group}
          value={activeGroup}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          setGroupNameEdit={setGroupNameEdit}
          groupNameUpdated={groupNameUpdated}
        />
      ))}
    </div>
  );
};

export default GroupInfo;
