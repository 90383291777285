import { useParams } from "react-router-dom";
import { UmojaDialog } from "../../components";
import UmojaSinglePaymentWidget from "../../components/Widgets/PaymentWidget/UmojaSinglePayment";
import { useOrganizations, useProjects } from "../../hooks";
import { mapCurrencySign } from "../../utils/currencyMap";

interface SingleDisbursementDialogProps {
  showSingleDisbursementDialog: boolean
  toggleSingleDisbursementDialog: Function
  wallet: any
}

const SingleDisbursementDialog = ({
  showSingleDisbursementDialog,
  toggleSingleDisbursementDialog,
  wallet,
}: SingleDisbursementDialogProps) => {

  const params = useParams<{ organizationId: string }>();

  const projects = useProjects();
  const project = projects?.data?.programs?.find(
    (data: any) => data.organization_id === +params?.organizationId
  );

  const orgDetails = useOrganizations();
  const selectedOrganization = orgDetails?.data?.find(
    (data: any) => data.id === +params?.organizationId
  );
  return (
    <UmojaDialog isOpen={showSingleDisbursementDialog}
      handleClose={toggleSingleDisbursementDialog}
      style={{ width : "330px" }} >
      <UmojaSinglePaymentWidget
        wallet={wallet}
        masterWalletBalance={selectedOrganization?.balance}
        project={project}
        handleCloseDialog={toggleSingleDisbursementDialog}
        currency={mapCurrencySign[project?.project_currency]}
      />
    </UmojaDialog>
  )
}

export default SingleDisbursementDialog;
