import React from 'react'
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components';
import useWhitelistedUsers from '../../hooks/useWhitelistedUser';
import { Table } from './WhiteListTable';
import DrawerHeaderButton from '../../components/Button/DrawerHeaderButton';
import { ReactComponent as Close } from "../close.svg";
interface WhitelistUsersProps {
  organizationId: string;
  setOpenWhitelist: Function;
}

const WhitelistUsers = ({ organizationId, setOpenWhitelist }: WhitelistUsersProps) => {
  const { t } = useTranslation()
    
    const WhitelistUsers = useWhitelistedUsers(organizationId)
    const columns = [
    {
      label: `${t("name")}`,
      accessor: 'name',
    },
    {
      label: `${t("user_type")}`,
      accessor: 'user_type'
    },
    {
      label: `${t("phone_number")}`,
      accessor: 'phone_number',
    },
    {
      label: `${t("enrollment_status")}`,
      accessor: 'enrollment_status',
    },
    ];
  
    if (WhitelistUsers.isLoading) {
    return (
      <Layout.Container>
        <div className="dashboard__spinner">
          <Spinner />
        </div>
      </Layout.Container>
    )
    }
  
  return WhitelistUsers?.data && ( 
    <Layout.Container>
      <div  style={{ padding: "15px", overflow:"scroll" }}>
      <div>
        <DrawerHeaderButton
            icon={<Close />}
            text="dismiss"
            color="#323232"
            handleClick={() => setOpenWhitelist(false)}
        />
      </div>
        <div style={{ fontSize: "25px", fontWeight: 700, marginBottom: "10px", marginTop: "10px"}}>{t("whitelist_user")}</div>
        <p style={{ width: "555px" }}>{ t("whitelist_information")}</p>
        <Table
          data={WhitelistUsers?.data.map((user: any) => ({
             name: user.name,
             user_type: user.type_id === 1 ? t('recipient') : t('merchant'),
             phone_number: user.phone_number,
            enrollment_status: user.is_enrolled ? <span style={{ backgroundColor: "#C5E3D8", padding: "5px", color: "#0D8050", marginLeft: "10px" }}>{t("enrolled")}</span> : <span style={{ backgroundColor: "#F7E6D4", padding: "5px", color: "#BF7326" , marginLeft: "10px"}}>{ t("not_enrolled")}</span>
            }))}
          columns={columns}
        />
      </div>
    </Layout.Container>
  )
}

export default WhitelistUsers