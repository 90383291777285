export async function downloadData(organizationId: string, projectId: number, lang: string) {
  const response = await fetch(
    `${process.env.REACT_APP_DISBURSEMENT_ENDPOINT}/${organizationId}/project/${projectId}/export?lang=${lang}` as string,
   {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return response;
}

export async function getProjects() {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/programs`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getCountries() {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/countries`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function downloadTemplate(fileUrl: string) {
  const response = await fetch(
    `${fileUrl}` as string,
   {
      method: "GET"
    }
  );
  return response;
}
