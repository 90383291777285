import { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  UmojaPaymentWidget,
  AnalyticsCard,
  TransactionCard,
  Button,
} from "../../components";
import { ProjectDropDownSelect } from "../../components/Dropdown/ProjectDropDownSelector";
import { useRole } from "../../hooks";
import { downloadData } from "../../services";
import useGroups from "../../hooks/useGroups";
import useGroupWallets from "../../hooks/useGroupWallets";
import {
  formatDemographicData,
  spendingPatternData,
} from "../ActivityExplorer/util";
import { mapCurrencySign } from "../../utils/currencyMap";
import { AtLeast } from "../../hooks/useRole";
import { fmtDecimal } from "../../utils";
import UmojaSpendingPatternChartV2 from "../../components/Widgets/ChartWidget/UmojaSpendingPatternChartV2";
import UmojaDemographicInsightChartV2 from "../../components/Widgets/ChartWidget/UmojaDemographicInsightChartV2";

export type RecipientWallet = {
  name: string;
  wallet_ids: number[];
};

export type Project = {
  name: string;
  id: number;
  project_currency?: string;
};

export type OrganizationWalletProps = {
  id: number;
  name: string;
  balance: string;
  currency: string;
};

type ProjectsProps = {
  id: number;
  name: string;
};

type BigInt = string | number;
type ProjectStats_Weekly = { this_week_total: BigInt; last_week_total: BigInt; vs_last_week_percentage: BigInt };
type ProjectStats_Basic = { total: BigInt; count: BigInt; avg: BigInt };
type ProjectStats_TrxsTotalizedByType = Record<string, ProjectStats_Basic>;
type ProjectStats_TrxsTotalizedByDay = Record<string, ProjectStats_Basic> & { date: string };
type ProjectStats_TrxsByType = Record<string, ProjectStats_TrxsTotalizedByType>;
type ProjectStats_TrxsByDay = Record<string, ProjectStats_TrxsTotalizedByDay[]>;

type ProjectStatsProps = {
  participants_count: BigInt;
  merchants_count: BigInt;
  beneficiares_count: BigInt;
  total_disbursements_amount: BigInt;
  pending_sales_code_intentions_inc_expired: BigInt;
  pending_sales_code_intentions_not_expired: BigInt;
  transactions_by_type: ProjectStats_TrxsByType;
  transactions_by_time: ProjectStats_TrxsByDay;
  weekly: Record<string, ProjectStats_Weekly>;
};

export interface GlobalOperationsProps {
  programWallet: OrganizationWalletProps;
  transactionsHistory: any[];
  project: Project;
  averageBalance: any;
  projectStats: ProjectStatsProps;
  projects: ProjectsProps[];
  demographicInsightStats: any;
  activityStats: any;
}

export const GlobalOperations = ({
  programWallet,
  project,
  transactionsHistory,
  averageBalance,
  projectStats,
  projects,
  demographicInsightStats,
  activityStats,
}: GlobalOperationsProps) => {
  const params = useParams<{ organizationId: string }>();
  const role = useRole();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const progCurrency = mapCurrencySign[project?.project_currency!];
  const locale = window.localStorage.getItem("i18nextLng") || "en";
  const download = async (project: Project) => {
    setDisabled(true);
    const data = await downloadData(params.organizationId, project.id, locale);
    const blob = await data.blob();
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "exported_program_data.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDisabled(false);
  };

  const [selectedGroup, setSelectedGroup] = useState("");
  const groups = useGroups(params.organizationId, project?.id);
  const groupWallets = useGroupWallets(
    params.organizationId,
    project?.id,
    selectedGroup
  );
   
  // get spending pattern volume
  // const spendingPatternVolume = spendingPatternData(projectStats?.volume);
  const spendingPatternVolume = spendingPatternData(activityStats?.all_trx_volume);

  // get demographic insight data
  const demographicInsightData = formatDemographicData(demographicInsightStats?.categories, progCurrency);  

  return (
    <div className={`${AtLeast.Organizer(role) ? "" : "global__operations-block-view"} global__operations-outer-wrapper`}>
      <div>
        <div className="global__operations-widget__header">
          <ProjectDropDownSelect projects={project} />
          <Button
            minimal
            onClick={() => download(project)}
            text={t("export_data")}
            disabled={disabled}
            className="global__operations-widget-export-button"
            icon={
              <Icon
                icon="download"
                iconSize={15}
                color="#BFCCD6"
                style={{ paddingBottom: 2 }}
              />
            }
          />
        </div>
        <div className={"global__operations-widget-grid"}>
          <div className="global__operations-widget-grid-left">
            <div className="global__operations-widget-stats">
              <AnalyticsCard
                title={`${t("participant_pulse")}`}
                leftHeader={`${t("merchants")}`}
                leftContent={projectStats?.merchants_count}
                rightHeader={`${t("recipients")}`}
                rightContent={projectStats?.beneficiares_count}
              />
              <AnalyticsCard
                title={`${t("flow_of_funds")}`}
                leftHeader={`${t("total_txns")} (${progCurrency})`}
                leftContent={projectStats?.transactions_by_type?.PURCHASE?.all.count}
                rightHeader={`${t("average_txn")} (${progCurrency})`}
                rightContent={`${fmtDecimal(projectStats?.transactions_by_type?.PURCHASE?.all.avg)}`}
                isLoading={projectStats?.transactions_by_type === undefined ? true : false }
              />
            </div>
            <div className="global__operations-widget-transactions">
              <TransactionCard
                title={`${t("merchant_wallet_balance")} (${progCurrency})`}
                content={`${fmtDecimal(averageBalance?.data?.avg_merchants_balance)}`}
                isLoading={averageBalance?.isFetching}
              />
              <TransactionCard
                title={`${t("total_disbursed")} (${progCurrency})`}
                content={`${fmtDecimal(projectStats?.total_disbursements_amount)}`}
                isLoading={projectStats?.total_disbursements_amount === undefined ? true : false}
              />
              <TransactionCard
                title={`${t("recipient_wallet_balance")} (${progCurrency})`}
                content={`${fmtDecimal(averageBalance?.data?.avg_beneficiaries_balance)}`}
                isLoading={averageBalance?.data?.avg_beneficiaries_balance === undefined ? true : false}
              />
              <TransactionCard
                title={`${t("total_spent")} (${progCurrency})`}
                content={`${fmtDecimal(projectStats?.transactions_by_type?.PURCHASE?.all.total)}`}
                isLoading={projectStats?.transactions_by_type === undefined ? true : false }
              />
              <div></div>
              <TransactionCard
                title={`${t("total_refunded")} (${progCurrency})`}
                content={`${fmtDecimal(projectStats?.transactions_by_type?.REFUNDED?.all?.total)}`}
                isLoading={projectStats?.transactions_by_type === undefined ? true : false }
              />
            </div>
            <div>
              <h3 className="demographic_insight-chart_header">
                {`${t("demographic_insight")} (${progCurrency})`}
              </h3>
              <UmojaDemographicInsightChartV2 data={demographicInsightData}/>
            </div>
            <div>
              <h3 className="demographic_insight-chart_header">
                {`${t("spending_pattern")}`}
              </h3>
              <UmojaSpendingPatternChartV2 data={spendingPatternVolume} currency={progCurrency} />
            </div>
          </div>
            {AtLeast.Admin(role) ? (
            <UmojaPaymentWidget
              disabled={false}
              wallets={groups}
              masterWalletBalance={programWallet?.balance}
              disbursementHistory={transactionsHistory}
              project={project}
              disbursementWallets={groupWallets}
              setSelectedGroup={setSelectedGroup}
              currency={progCurrency}
            />
            ) : null}
        </div>
      </div>
    </div>
  );
};
