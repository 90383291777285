import {
  format,
  isWithinInterval,
  subDays,
  subHours,
  subYears,
} from "date-fns";

const dateFormat = "MMM dd H:mm";

interface TimeFrameSelectProps {
  data: Record<string,any>[];
  timeframe: { start: string | Date; end: string | Date; };
}

export const timeFramedTrxVolume = (input: TimeFrameSelectProps) => {
  const dataSet = [];
  const interval = { start: new Date(input.timeframe.start), end: new Date(input.timeframe.end) };
  for( const entry of input.data ) {
    const entryDate = new Date(entry.datetime)
    if( !isWithinInterval( entryDate, interval ) ) continue;
    dataSet.push({ x: format(entryDate, dateFormat), y: Number(entry.total) }); 
  }
  return dataSet;
};

export const selectTimeframe = (timeframe: any) => {
  let currentTime = new Date();
  let min = subDays(currentTime, 365 * 2);
  let startTime;
  let endTime;

  switch (timeframe) {
    // the past hour
    case "timeframe-1h": {
      startTime = subHours(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // the past 2 hours
    case "timeframe-24h": {
      startTime = subHours(currentTime, 24);
      endTime = currentTime;
      break;
    }
    // the past week
    case "timeframe-1w": {
      startTime = subDays(currentTime, 7);
      endTime = currentTime;
      break;
    }
    // past year
    case "timeframe-1y": {
      startTime = subYears(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // all data
    case "timeframe-max": {
      startTime = min;
      endTime = currentTime;
      break;
    }
    // fallback
    default: {
      startTime = min;
      endTime = currentTime;
      break;
    }
  }

  return {
    start: startTime,
    end: endTime,
  };
};

export const findSum = (data: any[] = []) => {
  let sum = 0
  for( const item of data ) sum += Number(item.total)
  return sum;
};

export const spendingPatternData = (data: any) => {

 const spending =  data?.map((values: any) => ({
    date: format(new Date(values?.datetime), 'MM/dd/yy' ),
    Volume: Number(values?.total),
  }));

  return spending?.reverse()
};

export const formatDemographicData = (data: any, currency: string) => {  
  const newData = data?.map((category: any) => { 
    return {
      name: category.name,
      Male: category.male.total,
      Female: category.female.total,
      Other: category.other.total,
      total: category.total
     };
   })
  return newData;
};


export const offlineOnline = (data: any) => {
  return {
    
  }
};


