import { CurrencyInput } from "./inputs/CurrencyInput";
import { Button } from "@blueprintjs/core";
import { ReactSVG } from "react-svg";
import { Trans, useTranslation } from "react-i18next";
import { MasterWalletPanel } from "../WalletPanel/MasterWalletPanel/MasterWalletPanel";
import { useFormContext } from "react-form";

export interface UmojaDisbursementInputsProps {
  setMode: Function;
  values: any;
  balance: string;
  currency: string;
  organizationId: string;
}

export const DisbursementPanel = ({
  setMode,
  balance,
  currency,
  organizationId
}: UmojaDisbursementInputsProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const handlePanelChange = (panel: string | number) => {
    setMode(panel)
  };

  const { amount, programWalletIds } = values;
    
  return (
    <div className="payment__widget-content_wrapper">
      <div>
        <MasterWalletPanel title={`${t("disburse_funds")}`} balance={balance} currency={currency} />
      </div>
      <div className='disbursement__panel-section_wrapper'>
        <div className={
          `${amount
            ? "disbursement__panel-wallet_indicator_wrapper--active"
            : ""
          } disbursement__panel-wallet_indicator_wrapper`}
        >
          <div className="disbursement__panel-wallet_indicator--inner">
            <ReactSVG
              src="/disburseAmount.svg"
              className={`${amount
                ? "disbursement__panel-wallet_indicator--active"
                : ""}`
              }
            />
            <div className={`${amount
              ? "disbursement__panel-wallet_indicator--active-text"
              : "disbursement__panel-wallet_indicator--text"}`}
            >
            <div className="no-bottom-margin">{`${t("disburse")} ${t("amount")}`}</div>
            </div>
          </div>
            <CurrencyInput
              organizationId={organizationId}
              numberFormatClassName={`${amount ? `currency__input--filled ${amount.toString().length > 5 ? "long__input" : ""}` : ""}`}
              currency={currency}
            />
        </div>
        <div className={
          `${programWalletIds?.groupName
            ? "disbursement__panel-wallet_indicator_wrapper--active"
            : ""
          } disbursement__panel-wallet_indicator_wrapper`}
          onClick={() => setMode('target_group')}
        >
          <div className="disbursement__panel-wallet_indicator--inner">
            <ReactSVG
              src={"/disburseTo.svg"}
              className={`${programWalletIds?.groupName
                ? "disbursement__panel-wallet_indicator--active"
                : ""
                }`
              }
            />
            <div className={`${programWalletIds?.groupName
              ? "disbursement__panel-wallet_indicator--active-text"
              : "disbursement__panel-wallet_indicator--text"}`}
            >
              <p className="no-bottom-margin">
                {`${programWalletIds?.groupName
                  ? programWalletIds?.groupName
                  : t("disburse_to")}`
                }
              </p>
              <p className="no-bottom-margin">
                {`${programWalletIds?.groupName
                  ? `${programWalletIds?.groupWalletCount} ${t("wallets")}`
                  : t("select_target_group")
                  }`
                }
              </p>
            </div>
          </div>
          <ReactSVG src="/rightArrow.svg" />
        </div>
      </div>
      <Button
        text={<Trans i18nKey="preview_disbursement" />}
        fill
        minimal
        className="disbursement__details-button"
        onClick={() => handlePanelChange("preview")}
        disabled={!(amount && programWalletIds?.groupName) || Number(balance) <= 0 || amount > Number(balance)}
      />
    </div>
  );
};
