import { useTranslation } from "react-i18next";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import "./Breadcrumb.css";

export type BreadcrumbProps = {};

export default function Breadcrumb(props: BreadcrumbProps) {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs([
    // Do not want these breadcrumbs to render in the titles
    {
      path: "/",
      breadcrumb: null,
    },
    {
      path: "/dashboard",
      breadcrumb: null,
    },
    // These are okay
    {
      path: "/dashboard/settings",
      breadcrumb: `${t("settings")}`,
    },
    {
      path: "/dashboard/settings/account",
      breadcrumb: ` / ${t("account_settings")}`,
    },
    {
      path: "/dashboard/settings/organization",
      breadcrumb: ` / ${t("organization_settings")}`,
    },
    {
      path: "/dashboard/settings/program",
      breadcrumb: ` / ${t("program_management")}`,
    },
    {
      path: "/dashboard/settings/wallet-groups",
      breadcrumb: ` / ${t('wallet_group')}`,
    },
    {
      path: "/dashboard/settings/resources",
      breadcrumb: ` / ${t("additional_resources")}`,
    },
  ]);

  return (
    <div className="breadcrumbs__container">
      {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
    </div>
  );
}
