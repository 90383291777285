// @ts-nocheck - may need to be at the start of file
import React from 'react'

import { useTranslation } from "react-i18next";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { Pagination } from '../Wallets/Pagination';


export const Table = ({ columns, data }) => {
  const { t } = useTranslation()
  const {
    headerGroups,
    page,
    pageCount,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 8 }// Be sure to pass the defaultColumn option
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  return (
    <>
      <div style={{ width: "100%", marginTop: "10px", marginBottom: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F8FAFC" }}>
        <h2>{ t("imported_data")}</h2>
        <div style={{ width: "50%"}}>
            <div className="bp3-input-group">
              <span className="bp3-icon bp3-icon-search"></span>
              <input
                className="bp3-input"
                type="search"
                placeholder={t("wallet_search")}
                dir="auto"
                value={globalFilter || ""}
                onChange={e => setGlobalFilter(e.target.value)}
              />
        </div>
        </div>
       
      </div>
      <table>
        <thead>
        <tr>
        {headerGroups[0].headers.map((column: any, index) => {    
            return (
              <th style={{ whiteSpace: "nowrap", color: "black"}} key={index}>
                {column.label}
              </th>
            )
          })}
        </tr>
        </thead>
        <tbody>
        {page.map((row) => {
          prepareRow(row)
          return (
            <tr key={row?.original?.id}>
              {row.cells.map((cell: any, index) => {
                return <td key={index}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      <div style={{ width: "100%", height: "70px", justifyContent: "flex-start", display: "flex", flexDirection: "column", marginTop: "5px", marginBottom: "10px"}}>
        {pageOptions.length > 0 ? (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
          />
        ) : (
          <p style={{ margin: "15px", color: "red" }}>{t('no_record')}</p>
        )}
      </div>
    </>
  )
}
