/** Truncates the number up to <decimals> decimal digits. */
export function fmtNumber(n: string | number, decimals: number = 2): string {
	const regex = [...String(Number(n)).matchAll(/^(\d+)\.?(\d{0,})/g)][0];
	if (!regex || !regex.length) return String(n);
	const int = regex[1];
	const dec = regex[2] || "00";
	if (decimals == 0) return int; /* eslint-disable-line */
	if (dec.length > decimals) return `${int}.${dec.substring(0, decimals)}`;
	return `${int}.${dec}${"0".repeat(decimals - dec.length)}`;
}

/** Format number with localized commas: 1,234.25 (applies truncation) */
export function fmtDecimal(amount: string | number) {
	const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 });
	return formatter.format( Number( fmtNumber(+amount) ) );
};

/** Format number as money: $1,234.25 (applies truncation) */
export function formatMoney(amount: string | number, selectedCurrency: string) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: selectedCurrency || '',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});

	return formatter.format( Number( fmtNumber(amount) ) );
};

export function formatDecimalNumber(amount: string) {
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2});
    return formatter.format(+amount);
};
