import "./Inputs.css";
import NumberFormat from "react-number-format";
export interface UmojaCurrencyInputProps {
  value: string;
  setValue: Function;
  leftIcon?: JSX.Element;
  currencyPrefix: string;
  readOnly?: boolean;
  numberFormatClassName?: string
  organizationId?: string;
}

// TODO: Make border color dynamic based on state of form.
// Should be passed in as props and either purple (active), green (success), or gray (inactive)
export const UmojaCurrencyInput = ({
  value = "",
  setValue,
  currencyPrefix,
  readOnly,
  numberFormatClassName,
  organizationId
}: UmojaCurrencyInputProps) => {

  

  return (
    <label className="currency__input-wrapper" >
      <NumberFormat
        key={organizationId}
        id="focusId"
        className={`currency__input ${numberFormatClassName}`}
        placeholder={`${currencyPrefix}0.00`}
        value={value}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        prefix={currencyPrefix}
        onValueChange={(values) => {
          const { floatValue } = values;
          setValue(floatValue || 0);
        }}
        maxLength={16}
        readOnly={readOnly}
      />
    </label>
  );
};
