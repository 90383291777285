import { format, subDays } from "date-fns";
const dateFormat = "MMM dd H:mm:ss";

interface DashboardFeaturedChartProps {
  data: any;
}

// Plot all transaction amounts on a line chart
export const grossTransactionAmount = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data.length) {
    // handle empty dataset
    return [
      { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
      { x: format(new Date(), dateFormat), y: 0 },
    ];
  }
  return data.map((transaction: any) => {
    return {
      x: format(new Date(transaction.received_timestamp), dateFormat),
      y: transaction.amount,
    };
  });
};

// Group transactions by is_offline flag
export const groupTransactionsByOfflineStatus = ({
  data,
}: DashboardFeaturedChartProps) => {
  let online = data.online_trx_count;
  let offline = data.offline_trx_count;

  return [
    { x: "online", y: online },
    { x: "offline", y: offline },
  ];
};

// Total transaction volume. Increment subtotals, in progressively ascending line
export const totalIncrementingTransactionVolume = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data?.all_trx_volume?.length) {
    // handle empty dataset
    return [
      { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
      { x: format(new Date(), dateFormat), y: 0 },
    ];
  }
  let subTotal = 0;
  return data.all_trx_volume.map((mappedTransaction: any) => {
    subTotal = subTotal + mappedTransaction.total;
    return {
      x: format(new Date(mappedTransaction.datetime), dateFormat),
      y: subTotal,
    };
  });
};

// Demographic breakdown by age_group
export const segmentOrganizationAgeGroup = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data || data.length === 0) {
    return [
      { x: "Unknown", y: 0 },
      { x: "<18", y: 0 },
      { x: "19-30", y: 0 },
      { x: "30-40", y: 0 },
      { x: "40-50", y: 0 },
      { x: "50-60", y: 0 },
      { x: "60+", y: 0 },
    ];
  }
  const ageGroups = ["Unknown", "<18", "19-30", "30-40", "40-50", "50-60", "60+"];
  const value = Object.values(data);
   
  return ageGroups.map((ageGroup, index) => { 
      return { x: ageGroup, y: Number(value[index]) }
  });
};

export const calculateTotalTransactionVolume = (
  amount: string | number,
  totalRecipients: string | number
) => {
  return Number(amount) * Number(totalRecipients);
};

export const getMasterWalletBalance = (
  transactionVolume: string | number,
  presentBalance: string | number
) => {
  return Number(presentBalance) - Number(transactionVolume);
};

// TODO: Query the following graphs on homepage:
// Transaction volume
// Count of successful transactions per day
// Count of failed transactions per day

export const getGroupsData = (groupsQuery: any, t: any) => {
  if (!groupsQuery.data) {
    return [];
  }
};
