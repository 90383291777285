import "./Organizations.css";
import { Button, Layout, UmojaDialog, UmojaHeader } from "../../components";
import { UseTableCellProps } from "react-table";
import { useAccount, useOrganizations } from "../../hooks";
import { Trans, useTranslation } from "react-i18next";
import { TableV2 } from "../../components/TableV2";
import { Classes, Drawer, Spinner } from "@blueprintjs/core";
import { useState } from "react";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { useForm } from "react-form";
import { CreateNewOrganizationForm } from "./CreateNewOrganization";
import { AddMoneyDialog } from "./inputs/AddMoneyDialog";
import { mapCurrencySign } from "../../utils/currencyMap";
import { useQueryClient } from "react-query";

export type UmojaAccount = {
  id: number;
  account_id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_email_verified: string;
  role: string;
  created_at: Date;
  organization_id: number;
};

const OrganizationsScreen = () => {
  const account = useAccount();
  const organizations = useOrganizations(); 
  
  const { t } = useTranslation();
  const [showRevokeAccessDialog, setShowRevokeAccessDialog] = useState(false);
  const [showActivateDialog, setShowActivateDialog] = useState(false);
  const [activeOrganization, setActiveOrganization] = useState<any | null>(null);
  const [showAddMoneyDialog, setShowAddMoneyDialog] = useState(false);
  const [showAddOrganizationDrawer, setShowAddOrganizationDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const QueryClient = useQueryClient();
  
  const columns = [
    {
      Header: t("organization_name"),
      accessor: "name",
    },
    {
      Header: t("organization_type"),
      accessor: "type"
    },
    {
      Header: t("customer_email"),
      accessor: "contact_email",
     },
     {
       Header: t('status'),
       accessor: "status",
     },
     {
       Header: t('action'),
       accessor: "id",
       Cell: (instance: UseTableCellProps<any>) => {
         return (
          //  <ButtonGroup style={{ minWidth: 200 }} >
          //    <Button
          //      icon="add"
          //      text={t("add_money")}
          //      onClick={() => {
          //        setActiveOrganization(instance.row.original)
          //        setShowAddMoneyDialog(true)
          //       }
          //      }
          //      key={instance.row.id}
          //    />
          //    {
          //      instance?.row?.original?.is_active ?
          //        (
          //          <Button
          //            icon="badge"
          //            text={t("revoke_access")}
          //            onClick={() => {
          //              setActiveOrganization(instance.row.original)
          //              setShowRevokeAccessDialog(true)
          //            }}
          //          />
          //        ) :
          //        <Button
          //          icon="badge"
          //          text={t("activate")}
          //          onClick={() => setShowActivateDialog(true)}
          //        />
          //    }
          //  </ButtonGroup>
             <Button
                icon="badge"
                text={t("revoke_access")}
                onClick={() => {
                  setActiveOrganization(instance.row.original)
                  setShowRevokeAccessDialog(true)
                }}
                 disabled={!instance?.row?.original?.is_active}
               />
         )
       }
     }
   ];
  
  const handleRevokeAccess = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_DISBURSEMENT_ENDPOINT}/${activeOrganization?.id}/revoke-access` as string,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      }
    );
    const responseData = await response.json();
    setLoading(false);
    setShowRevokeAccessDialog(false)
    if (responseData.revoked_demo_access) {
      UmojaToast.show({
        message: t("organization_revoked_successfully"),
        intent: "success",
        icon: "tick",
      });
      await QueryClient.invalidateQueries("organizations");
      await QueryClient.invalidateQueries("me");
    } else {
      UmojaToast.show({
        message: t("organization_revoke_failed"),
        intent: "danger",
        icon: "error",
      });
    }
    setActiveOrganization(null)
  };

    const handleActivateOrganization = async () => {
    setLoading(true);
    const response = await fetch(
      process.env.REACT_APP_ACTIVATE_ORGANIZATION_ACCESS_ENDPOINT as string,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
           organizationId: activeOrganization.id,
        }),
      }
    );
    const responseData = await response.json();
    setLoading(false);
    setShowRevokeAccessDialog(false)
    if (responseData.status === 200) {
      UmojaToast.show({
        message: t("organization_activated_successfully"),
        intent: "success",
        icon: "tick",
      });
    } else {
      UmojaToast.show({
        message: t("organization_activate_failed"),
        intent: "danger",
        icon: "error",
      });
    }
    setActiveOrganization(null)
  };

    const PaymentWidgetForm = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setLoading(true);
      // handle api here
      setLoading(false);
      UmojaToast.show({
        message: 'Payment successful',
        intent: "success",
        icon: "tick",
      });
      instance.reset();
    },
    // debugForm: true,
  });

  // const { values } = PaymentWidgetForm;

  if (organizations.isLoading) {
    return (
      <Layout.Container>
        <div className="dashboard__spinner">
          <Spinner />
        </div>
      </Layout.Container>
    )
  }
   
  return  (
    <Layout.Container>
      <UmojaDialog isOpen={showRevokeAccessDialog} handleClose={() => setShowRevokeAccessDialog(false)} style={{ width: "400px"}}>
        <div className={Classes.DIALOG_HEADER}>
          <h4 className={Classes.HEADING} style={{ marginTop: "10px", fontWeight: "bold"}}>{t('revoke_access')}</h4>
        </div>
        <div className={Classes.DIALOG_BODY}>
            <Trans i18nKey="revoke_access_confirmation">
              Are you sure you want to revoke access to <strong> {{ organization: activeOrganization?.name }} </strong>?
            </Trans>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => setShowRevokeAccessDialog(false)}
              text={t("back")}
            />
            <Button
              onClick={handleRevokeAccess}
              intent="primary"
              text={t("confirm")}
              loading={loading}
            />
          </div>
        </div>
      </UmojaDialog>
      <UmojaDialog isOpen={showActivateDialog} handleClose={() => setShowActivateDialog(false)} style={{ width: "400px"}}>
        <div className={Classes.DIALOG_HEADER}>
          <h4 className={Classes.HEADING} style={{ marginTop: "5px"}}>{t('activate_organization')}</h4>
        </div>
        <div className={Classes.DIALOG_BODY}>
          <Trans i18nKey="activate_organization_confirmation">
            Are you sure you want to enable access for <strong> {{ organization: activeOrganization?.name }} </strong>?
          </Trans>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => setShowActivateDialog(false)}
              text={t("back")}
            />
            <Button
              onClick={handleActivateOrganization}
              intent="primary"
              text={t("confirm")}
              loading={loading}
            />
          </div>
        </div>
      </UmojaDialog>
      <UmojaDialog
        isOpen={showAddMoneyDialog}
        handleClose={() => setShowAddMoneyDialog(false)}
        style={{ width: "400px" }}
      >
        <PaymentWidgetForm.Form>
          <AddMoneyDialog organizationName={activeOrganization?.name} currency={mapCurrencySign[activeOrganization?.currency]} />
        </PaymentWidgetForm.Form>
      </UmojaDialog>
      <UmojaHeader
        title={t("organizations")}
        user={account.data} />
      <Drawer
        isOpen={showAddOrganizationDrawer}
        canOutsideClickClose
        onClose={() => setShowAddOrganizationDrawer(false)}
        style={{ width: "50%", backgroundColor: "#FFFFFE", maxWidth: "1210px" }}
        enforceFocus={false}
      >
        <div style={{ overflow: "scroll"}}>
          <CreateNewOrganizationForm setShowAddOrganizationDrawer={setShowAddOrganizationDrawer} />
        </div>
      </Drawer>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", paddingLeft: "20px", paddingRight: "50px",  borderBottom: '1px solid #edeff1' }}>

        <div style={{ marginBottom: "10px"}}>
          <Button icon="add-row-bottom" text={t("add_organization")}  large onClick={() => setShowAddOrganizationDrawer(true) }/>
        </div>
        
      </div>
      <TableV2 columns={columns}
        data={organizations?.data?.map((data: Record<string, any>) => ({
          ...data,
          status: data.is_active ? t("active") : t("suspended"),
        }))}
       />
    </Layout.Container>
  );
};

export default OrganizationsScreen;
