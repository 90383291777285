import './walletGroup.css'
import React, { FunctionComponent, useEffect, useState } from "react";
import * as _ from "lodash";
import { H4, H3, Card, H5 } from "@blueprintjs/core";
import AddGroupButton from "./AddGroupButton";
import { Button } from "../../components";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import GroupInfo from "./GroupInfo";
import useSearchParticipant from "../../hooks/useSearchParticipant";
import ParticipantContainer from "./PaticipantContainer";
import { useMutation, useQueryClient } from "react-query";
import useGroupRecipients from "../../hooks/useGroupRecipients";
import { useTranslation } from "react-i18next";
import { RecipientWallet } from "../../components/Widgets/PaymentWidget/panels/TargetGroupPanel";
import { ReactSVG } from "react-svg";
import { DeleteGroupDialog } from "./DeleteGroupDialog";
import { capitalize } from "lodash";
import { RemoveAllWalletsDialog } from './RemoveAllWalletsDialog';
import { DiscardInputsDialog } from './DiscardInputDialog';

interface WalletGroupProps {
  organizationId: string,
  programId: string
  groups: any
}

export interface IParticipantWallet {
  age_group: number
  enrolled_at: string
  gender: string
  id: number
  locked: boolean
  name: string
  phone_number: string
  program_id: number
  type_id: number
  umoja_wallet_id: number
  selected?: boolean
}

interface UpdateWalletGroupMutationParam {
  organizationId: string,
  programId: string,
  groupId: number  | undefined,
  addWallets: any[],
  removeWallets: any[]
  groupName: string | undefined
}

interface DeleteMutationGroupParam {
  organizationId: string,
  programId: string | undefined,
  groupId: string | undefined,
}

interface CreateMutationGroupParam {
  organizationId: string,
  programId: string
  groupName: string
  finalSelectedWallet: IParticipantWallet[]
}


const WalletGroup: FunctionComponent<WalletGroupProps> = ({ groups, programId, organizationId }) => {
  const [groupName, setGroupName] = useState('');
  const [ , setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSelectedWallets, setCurrentSelectedWallets] = useState<IParticipantWallet[] | []>([]);
  const [searchView, setSearchView] = useState(false);
  const [finalSelectedWallet, setFinalSelectedWallet] = useState<IParticipantWallet[] | []>([]);
  const [walletExitError] = useState<IParticipantWallet[]  | []>([]);
  const [editView, setEditView] = useState(true);
  const [addWallet, setAddWallet] = useState<IParticipantWallet[] | []>([]);
  const [removeWallet, setRemoveWallet] = useState<IParticipantWallet[] | []>([]);
  const [editableWallets, setEditableWallets] = useState<IParticipantWallet[] | []>([]);
  const [groupNameEdit, setGroupNameEdit] = useState(false);
  const [groupNameUpdated, setGroupNameUpdated] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEmptyDeleteDialog, setEmptyDeleteDialog] = useState(false);
  const [listModified, setListModified] = useState(false);
  const [groupNameInputError, setGroupNameInputError] = useState(false);
  const [activeGroup, setActiveGroup] = useState<RecipientWallet | null>(null);
  const [nextActiveGroup, setNextActiveGroup] = useState<RecipientWallet | null>(null);
  const [createView, setCreateView] = useState(false);
  const [openDiscardInputDialog, setOpenDiscardInputDialog] = useState(false);


  const handleSetActiveGroup = (group: any) => {
    if (editView) {
      setNextActiveGroup(group);
    }
    if (
      (createView && finalSelectedWallet.length > 0) ||
      (createView && groupName) ||
      (groupNameUpdated) ||
      (editView && removeWallet.length > 0) ||
      (editView && addWallet.length > 0)) {
      setOpenDiscardInputDialog(true);
      return
    }
    setActiveGroup(group);
    setCreateView(false);
  }

  const handleDiscardInputs = () => { 
    if (createView) {
      setFinalSelectedWallet([]);
      setAddWallet([]);
      setCreateView(false);
      setGroupName('');
      setGroupNameInputError(false);
      setActiveGroup(null);
      setFinalSelectedWallet([]);
    }
      setOpenDiscardInputDialog(false);
      setActiveGroup(nextActiveGroup);
      setRemoveWallet([]);
      setAddWallet([]);
      setGroupNameUpdated(false);
      setGroupNameEdit(false);
      setListModified(false);
  }

  const sortedGroups = groups?.sort((a:any, b:any) => b.groupId - a.groupId )

  const { t } = useTranslation();

  const QueryClient = useQueryClient()

  const searchResult = useSearchParticipant(organizationId, programId, capitalize(searchTerm), finalSelectedWallet);
  const groupRecipient = useGroupRecipients(organizationId, activeGroup?.projectId, activeGroup?.groupId);
  
  useEffect(() => {
    if(groupRecipient){
      setEditableWallets(groupRecipient)
    }
    if(activeGroup?.groupName){
      setGroupName(activeGroup?.groupName)
    }
  }, [groupRecipient, activeGroup?.groupName]);


  useEffect(() => {
    setSearchTerm('')
  }, [activeGroup]);


  useEffect(() => {
    if(searchResult.length > 0){
      setSearchView(true)
    }else {
      setSearchView(false)
    }
  }, [searchResult, searchTerm]);


  const createGroupMutation = useMutation( async (value:CreateMutationGroupParam) => (
    await fetch(
      `${process.env.REACT_APP_DISBURSEMENT_ENDPOINT}/${organizationId}/projects/${programId}/group` as string,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          name: groupName,
          add_wallets: [...finalSelectedWallet.map((w: IParticipantWallet) => w.id)]
        }),
      }
    )
  ))


  const deleteGroupMutation = useMutation(async (value:DeleteMutationGroupParam) => (
    await fetch(
      `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${value.organizationId}/projects/${value.programId}/group/${value.groupId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
  ))

  const updateWalletGroupMutation = useMutation(async (value: UpdateWalletGroupMutationParam) => (
    await fetch(
      `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${value.organizationId}/projects/${value.programId}/group/${value.groupId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          remove_wallets: value.removeWallets,
          name: value.groupName,
          add_wallets: value.addWallets
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json"
        }
      }
    )
  ));

  const handleSearchParticipant = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const addSelectedParticipant = () => {
    if(editView){
      setListModified(true)
      setAddWallet([...addWallet, ...currentSelectedWallets])
      setEditableWallets([...editableWallets, ...currentSelectedWallets])
      setCurrentSelectedWallets([])
    }else {
      setFinalSelectedWallet([...finalSelectedWallet, ...currentSelectedWallets])
    }
    setSearchView(false)
    setSearchTerm("")
  }

  const handleGroupNameInput = (e: React.ChangeEvent<HTMLInputElement>) =>  {
    e.preventDefault()
    if(e.target.value.length > 20){
      setGroupNameInputError(true)
    }else {
      setGroupNameInputError(false)
    }
    setGroupName(e.target.value)
    setGroupNameUpdated(true)
  }

  const handleDeleteGroup = async () => {

    await deleteGroupMutation.mutate({
      organizationId, programId: programId, groupId: String(activeGroup?.groupId)
    }, {
      onSuccess : () => {
        QueryClient.invalidateQueries(['groups'])
        setActiveGroup(null)
      }
    })

    if(deleteGroupMutation.status === "error"){
      UmojaToast.show({
        message: t('delete_group_error'),
        intent: "danger",
        icon: "issue",
      });
    }
    UmojaToast.show({
      message: t('delete_group_success'),
      intent: "success",
      icon: "tick",
    });
  }

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }


  const handleUpdateWalletGroup = async () => {
    updateWalletGroupMutation.mutate(
      {
        organizationId,
        programId: programId,
        groupId: activeGroup?.groupId,
        removeWallets:removeWallet.map((w:IParticipantWallet) => w.id),
        addWallets: addWallet.map((w:IParticipantWallet) => w.id),
        groupName: groupName ? groupName : activeGroup?.groupName
      },{
        onSuccess: () => {
          QueryClient.invalidateQueries(['groupRecipients'])
          QueryClient.invalidateQueries(['groups'])
        }
      }
    )
    if(updateWalletGroupMutation.status === "error"){
      UmojaToast.show({
        message: t('update_group_error'),
        intent: "danger",
        icon: "issue",
      });
    }
    UmojaToast.show({
      message: t('update_group_success'),
      intent: "success",
      icon: "tick",
    });
    setListModified(false)
  }

  const handleCreateGroup  = async ()  => {
    createGroupMutation.mutate(
      {
        organizationId,
        programId,
        groupName,
        finalSelectedWallet
      },
      {
        onSuccess: () => {
          QueryClient.invalidateQueries(['groups'])
        }
      }
    )
    if (createGroupMutation.status === "error") {
      UmojaToast.show({
        message: t('create_group_error'),
        intent: "danger",
        icon: "issue",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    UmojaToast.show({
      message: t('create_group_success'),
      intent: "success",
      icon: "tick",
    });
  }

  const handleUpdateGroupName = () => {
    setListModified(true)
    setGroupNameEdit(true)
    setGroupName(activeGroup?.groupName || "")
  }

  const handleRemoveWallet = () => {
    if(createView){
      setFinalSelectedWallet(_.difference(finalSelectedWallet, currentSelectedWallets))
      setCurrentSelectedWallets([])
      return
    } 
    setRemoveWallet([...removeWallet, ...currentSelectedWallets])
    if (_.difference(editableWallets, currentSelectedWallets).length === 0) {
      setEmptyDeleteDialog(true)
      return
    }
    setEditableWallets(_.difference(editableWallets, currentSelectedWallets))
    setListModified(true)
  }
  
  return (
    <>
      <DiscardInputsDialog
        isOpen={openDiscardInputDialog}
        handleClose={() => setOpenDiscardInputDialog(false)}
        discard={handleDiscardInputs}
      />
      <RemoveAllWalletsDialog
       isOpen={openEmptyDeleteDialog}
        handleClose={() => setEmptyDeleteDialog(false)}
        deleteGroup={handleDeleteGroup}
      />
      <DeleteGroupDialog
        isOpen={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        deleteGroup={handleDeleteGroup}
      />
     <Card className={"wallet_group_wrapper"}>
      <H3 style={{ marginBottom: "20px"}}>{t('manage_wallet_group')}</H3>
      <div className={"wallet_group_top_bar"}>
        <Card style={{ width: "330px"}}>
          <H4>{t('wallet_groups')}</H4>
          <GroupInfo
            groups={sortedGroups}
            setActiveGroup={handleSetActiveGroup}
            activeGroup={activeGroup}
            setGroupNameEdit={setGroupNameEdit}
            groupNameUpdated={groupNameUpdated}
          />
          <AddGroupButton
            setCreateView={setCreateView}
            setEditView={setEditView}
            setActiveGroup={setActiveGroup}
            setGroupName={setGroupName}  
          />
        </Card>
        <Card style={{ width:  "60%" }}>
          { !activeGroup  && !createView ? (
            <div className={"wallet_group_title_container"}>
              <H4>{t('select_wallet')}</H4>
            </div>
          ) : (
            <>
              <div className={"wallet_group_top_action_container"}>
                {createView || groupNameEdit ?
                  <div>
                    <input
                      type="text"
                      placeholder={`${t('group_name')}....`}
                      dir="auto" style={{ border: "0", outline: "inherit", fontSize: "20px", width: "300px", marginBottom: "0px" }}
                      name="groupName"
                      value={groupName}
                      onChange={handleGroupNameInput} />
                     {groupNameInputError ? <div style={{ color: "red", marginLeft: "5px" }}>{t('group_name_error')}</div> : <></>}
                  </div>
                   :
                  <div style={{ width: "300px", display: "flex", alignItems: "center", height: "50px"}}>
                    <H4 style={{ marginRight: "5px", paddingTop: "10px", fontSize: "15px"}}>{activeGroup?.groupName }</H4>
                    {
                      activeGroup?.groupTag === null ? <span className={"circle"} onClick={handleUpdateGroupName}><ReactSVG src={"/edit.svg"} /></span> : <span></span>
                    }
                  </div>
                }
                <Button
                  text={t('delete_group')}
                  minimal
                  type="button"
                  className={"add_group_button button_basic"}
                  disabled={!!activeGroup?.groupTag || createView }
                  onClick={handleOpenDeleteDialog}/>
              </div>
              <div style={{ height: "85%" , width: "100%"}}>
              <div className={"wallet_table_container"}>
                  <div className="bp3-input-group bp3-round bp3-minimal search_input_container">
                    <span className="bp3-icon">
                     <ReactSVG src={"/wallet-add-button.svg"}  />
                    </span>
                    <input
                      className="bp3-input search_input"
                      type="search" value={searchTerm}
                      style={{ width: "380px"}}
                      placeholder={t('search_participants')}
                      dir="auto"
                      onChange={handleSearchParticipant}
                   />
                  </div>
                  <Button
                    text={t('add_to_list')}
                    minimal
                    type="button"
                    disabled={!!activeGroup?.groupTag  || currentSelectedWallets.length <= 0 || !searchView }
                    className={"button_add"}
                    onClick={addSelectedParticipant}/>
                </div>
                <div className={"wallet_group_list_wrapper"}>

                  {
                    walletExitError.length > 0 ? (
                      <ul>
                        { walletExitError.map((w:IParticipantWallet) => (<li color={"red"}>{ `${w.name} Already Exit` }</li>))}
                      </ul>
                    ): (<div></div>)
                  }
                      {
                        createView && searchResult.length <= 0 && finalSelectedWallet.length <= 0 ?
                        <div className={"wallet_group_title_container"}>
                          <H5>{t('create_group_inner_text')}</H5>
                        </div>
                        :
                  <ParticipantContainer
                    creatView={createView}
                    groupRecipient={editableWallets}
                    editView={editView}
                    searchView={searchView}
                    searchResult={searchResult}
                    finalSelectedWallet={finalSelectedWallet}
                    setSelectedWallets={setCurrentSelectedWallets}
                    />
                    
                  }
                </div>
              </div>
              <div className={"button__container"}>
                <div className={"wallet_group_action-footer"}>
                  {
                    currentSelectedWallets.length > 0 ? (
                      <Button
                      minimal
                      type="button"
                      disabled={!!activeGroup?.groupTag}
                      text={`${ currentSelectedWallets.length > 0 ? currentSelectedWallets.length : 0 } ${t('selected')}`}
                      className={"button_basic button_space"} />
                    ): (
                      <div className={createView ? "select_container_grey" : "select_container_black"}>
                        <ReactSVG src={createView ? "/confirm-grey.svg": "/confirm.svg"}  /><div style={{ marginLeft: "5px"}}>{t('select')}</div>
                      </div>
                    )
                  }
                  { currentSelectedWallets.length <= 0  ? <div></div> : <Button
                    minimal
                    type="button"
                    intent={"danger"}
                    text={t('remove_from_list')}
                    disabled={(!searchView && currentSelectedWallets.length <= 0) || !!activeGroup?.groupTag || !!searchView }
                    className={"button_remove"}
                    onClick={handleRemoveWallet} />
                  }
                </div>
                <div>
                  {
                    createView ?
                      <Button
                            minimal
                            type="button"
                            text={t('create_group')}
                            onClick={handleCreateGroup}
                            className={"button_add"}
                            disabled={!!groupNameInputError || (!!createView && finalSelectedWallet.length <= 0) || groupName.length <= 0}
                        /> :
                      <Button
                        minimal
                        type="button"
                        text={t('save_changes')}
                        className={"button_basic"}
                        disabled={!!activeGroup?.groupTag || !!groupNameInputError ||!listModified }
                        onClick={handleUpdateWalletGroup}
                      />
                  }
                </div>
              </div>
            </>
          )}
        </Card>
      </div>
    </Card>
  </>
  );
};

export default WalletGroup;


