import React, { FunctionComponent } from 'react';
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { RecipientWallet } from "../../components/Widgets/PaymentWidget/panels/TargetGroupPanel";
import { Icon } from "@blueprintjs/core";

interface WalletGroupButtonProps {
  group: RecipientWallet
  setActiveGroup: Function,
  activeGroup: RecipientWallet | null,
  setGroupNameEdit: Function
  value: any
  groupNameUpdated: boolean
}

const WalletGroupButton: FunctionComponent<WalletGroupButtonProps> = ({ group, setActiveGroup, activeGroup,setGroupNameEdit, groupNameUpdated }) => {
  const { t } = useTranslation();

  const toggleSelected = () => {  
    setActiveGroup(group);
    setGroupNameEdit(groupNameUpdated ? true : false)
  };

  const isActive = activeGroup?.groupId === group?.groupId;
  return (
         <div style={{ width: "300px"}}>
           <div className={
             `${isActive
               ? "disbursement__panel-wallet_indicator_wrapper--active"
               : ""
             } disbursement__panel-wallet_indicator_wrapper`}
                onClick={toggleSelected}
                style={{ marginBottom: "5px"}}
           >
             <div className="disbursement__panel-wallet_indicator--inner">
               <ReactSVG
                 src={group.groupTag ? "/disburseTo.svg": "/disburseActive.svg"}
                 className={`${isActive
                   ? "disbursement__panel-wallet_indicator--active"
                   : ""
                 }`
                 }
               />
               <div className={`${isActive
                 ? "disbursement__panel-wallet_indicator--active-text"
                 : "disbursement__panel-wallet_indicator--text"}`}
               >
                 <p className="no-bottom-margin button_text">
                   {`${ group?.groupName}`}
                 </p>
                 <p className="no-bottom-margin button_text">
                   {`${group?.groupWalletCount} ${t("wallets")}`}
                 </p>
               </div>
             </div>
             {
               group.groupTag ? <ReactSVG src={"/settings-black.svg"}  /> : <Icon icon={"cross"} iconSize={20} color={"white"} />
             }
           </div>
         </div>

  );
};

export default WalletGroupButton;
