import "./__progressBar.css";
import { ReactComponent as Close } from "../../../../close.svg";
import { Drawer } from "@blueprintjs/core";
import { useMemo } from "react";
import { Button, Table, TextInput } from "../../../../../components";
import DrawerHeaderButton from "../../../../../components/Button/DrawerHeaderButton";
import { useTranslation } from "react-i18next";
import { formatErrorArray } from "../../../../../utils/errorMapping";



interface ExcelPreviewPanelProps {
  isOpen: boolean;
  onClose: Function;
  data: any[];
  file: File | null;
  setValue: Function;
  userType: string;
  setShowPreview: Function
  setDisable: Function
}

export const ExcelPreviewPanel = ({
  isOpen,
  onClose,
  data,
  file,
  setValue, setShowPreview,
  userType, setDisable
}: ExcelPreviewPanelProps) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('first_name'),
        accessor: "first_name",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.first_name?.length > 0 ? 'red' : ''}`}>
              {
                 row.original?.errors?.first_name?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.first_name)}`) : row.original.first_name
              }
            </span>
          )
        },
      },
      {
        Header: t('last_name'),
        accessor: "last_name",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.last_name?.length > 0 ? 'red' : ''}`}>
              {
                row.original?.errors?.last_name?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.last_name)}`) : row.original.last_name
              }
            </span>
          )
        },
      },
      {
        Header: t('phone_number'),
        accessor: "phone_number",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.phone_number?.length > 0 ? 'red' : ''}`}>
              {
                 row.original?.errors?.phone_number?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.phone_number)}`) : row.original.phone_number
              }
            </span>
          )
        },
      },

      {
        Header: t('age_group'),
        accessor: "age_group",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.age_group?.length > 0 ? 'red' : ''}`}>
              {
                row.original?.errors?.age_group?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.age_group)}`) : row.original.age_group
              }
            </span>
          )
        },
      },
      {
        Header: t('gender'),
        accessor: "gender",
        Cell: ({ row }: any) => {
          return (

            <span className={`${ row.original?.errors?.gender?.length > 0 ? 'red' : ''}`}>
              {
                 row.original?.errors?.gender?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.gender)}`) : row.original.gender
              }
            </span>
          )
        },
      },
      {
        Header: t('program'),
        accessor: "project_name",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.project_name?.length > 0 ? 'red' : ''}`}>
              {
                 row.original?.errors?.project_name?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.project_name)}`) : row.original.project_name
              }
            </span>
          )
        },
      },
      {
        Header: t('enrollment_date'),
        accessor: "enrolled_at",
        Cell: ({ row }: any) => {
          return (
            <span className={`${row.original?.errors?.enrolled_at?.length > 0 ? 'red' : ''}`}>
              {
                 row.original?.errors?.enrolled_at?.length > 0 ? t(`${formatErrorArray(row.original?.errors?.enrolled_at)}`) : row.original.enrolled_at
              }
            </span>
          )
        },
      },
    ],
    [t]
  );
  return (
    <Drawer isOpen={isOpen} canOutsideClickClose onClose={() => onClose()}>
      <div className="drawer__wrapper">
        <div>
          <DrawerHeaderButton
            icon={<Close />}
            text="dismiss"
            color="#323232"
            handleClick={() => onClose()}
          />
        </div>
        <div className="participant__title-wrapper">
          <div className="participant__info-wrapper">
            <h1 className="participant__info-name">{t("review_imported_data")}</h1>
            <p className="participant__info-blurb">
              {t("verify_imported_data_text")}
            </p>
          </div>
          <div className="participant__info-actions"></div>
        </div>
      </div>

      <div className="merchant__preview-file_preview_wrapper">
        <h2 className="merchant__preview-file_title">{t("imported_data")} </h2>
        <TextInput
          readOnly
          defaultValue={file?.name}
          className="merchant__preview-file_input"
        />
        <Button
          text={t("remove")}
          intent="primary"
          className="merchant__preview-file_button"
          onClick={() => {
            setValue("");
            userType === "recipient" && localStorage.removeItem("recipient");
            userType === "merchant" && localStorage.removeItem("merchant");
            onClose();
          }}
        />
        <Button
          text={t("yes_correct")}
          intent="success"
          className="merchant__preview-file_button"
          onClick={() => {
            setValue(file);
            setShowPreview(false)
            setDisable(true)
          }}
        />
      </div>

      {isOpen && (
        <div className="table_wrapper">
           <Table
            columns={columns}
            data={data.map((participant) => {
             return {
              first_name: `${participant?.parsed?.first_name}`,
              last_name: `${participant?.parsed?.last_name}`,
              phone_number: participant?.parsed?.phone_number,
              age_group: participant?.parsed?.age_group,
              gender: participant?.parsed?.gender,
              project_name: "",
              enrolled_at: "",
              errors: participant.errors_map,
            };
          })}
          />
        </div>
      )}
    </Drawer>
  );
};
